<template>
  <div class="timer">
    <Tabs v-model="tab">
      <TabPane :disabled="isBegin" label="正计时" name="normal"></TabPane>
      <TabPane :disabled="isBegin" label="倒计时" name="reverse"></TabPane>
    </Tabs>
    <p class="related hand" @click="onRelateClick" :title="allTitle()">
      <Text style="width: 180px" ellipsis>
        <Icon type="ios-paper" size="22" />
        <span>{{ relatedTitle }}</span>
      </Text>
      <Icon
        v-show="taskList.length > 0 && !isBegin"
        class="delete"
        size="22"
        type="ios-close-circle"
        color="#ccc"
        @click.stop="taskList = []"
      />
    </p>
    <p
      v-show="isBegin || tab == 'normal'"
      style="font-size: 44px; text-align: center"
    >
      {{ formatSeconds }}
    </p>
    <TimePicker
      v-show="tab == 'reverse' && !isBegin"
      format="HH:mm"
      placeholder="倒计时"
      @on-change="timeChange"
      style="width: 100%"
    />
    <div style="text-align: right; padding: 10px">
      <Button v-show="!isBegin" shape="circle" type="primary" @click="onBegin"
        >开始</Button
      >
      <Button
        v-show="isBegin"
        shape="circle"
        style="margin-right: 10px"
        @click="pasuseClick"
        >{{ isPause ? "继续" : "暂停" }}</Button
      >
      <Button v-show="isBegin" shape="circle" @click="intervalEnd">结束</Button>
    </div>
    <XSearch v-model="searchShow" :onSelected="onSelected" />
  </div>
</template>

<script>
import XSearch from "./XSearch";
import { absorbedAdd } from "@/common/api";

export default {
  components: {
    XSearch,
  },
  data() {
    return {
      tab: "normal",
      isBegin: false,
      isPause: false,
      originSeconds: 0, // 初始的秒数
      seconds: 0,
      interval: {},
      intervalList: [], // 间隔列表
      searchShow: false,
      taskList: [],
    };
  },
  watch: {
    isBegin(newVal) {
      this.$emit("statusChange", newVal);
    },
  },
  computed: {
    formatSeconds() {
      let hours = Math.floor(this.seconds / 3600);
      let minutes = Math.floor((this.seconds % 3600) / 60);
      let seconds = this.seconds % 60;

      let showHours = hours < 10 ? "0" + hours : hours + "";
      let showMinutes = minutes < 10 ? "0" + minutes : minutes + "";
      let showSeconds = seconds < 10 ? "0" + seconds : seconds + "";
      if (hours == 0) {
        return `${showMinutes}:${showSeconds}`;
      } else {
        return `${showHours}:${showMinutes}:${showSeconds}`;
      }
    },
    relatedTitle() {
      if (this.taskList && this.taskList.length > 0) {
        let task = this.taskList[0];
        if (this.taskList.length == 1) {
          return task.title;
        } else {
          return task.title + `  等${this.taskList.length}条任务`;
        }
      }
      return "关联任务";
    },
  },
  methods: {
    allTitle() {
      let str = "";
      this.taskList.map((item, index) => {
        str += `${index + 1}.${item.title}；`;
      });
      return str;
    },
    onBegin() {
      if (this.tab == "normal") {
        this.normalInterval();
      } else {
        if (this.originSeconds == 0) {
          this.$Message.warning("请先设置倒计时时间");
          return;
        }
        this.seconds = this.originSeconds;
        this.reverseInterval();
      }
      this.isBegin = true;
      this.insertStartTime();
    },
    normalInterval() {
      this.interval = setInterval(() => {
        this.seconds++;
      }, 1000);
    },
    reverseInterval() {
      this.interval = setInterval(() => {
        this.seconds--;
        if (this.seconds == 0) {
          this.intervalEnd();
          this.$Message.info("倒计时结束");
        }
      }, 1000);
    },
    intervalEnd() {
      let record = 0;
      if (this.tab == "normal") {
        record = this.seconds;
      } else {
        record = this.originSeconds - this.seconds;
      }
      if (record < 60) {
        this.$Message.info("时间小于一分钟，已忽略本次专注");
      } else {
        this.insertEndTime();
        let startTime = 0;
        let endTime = 0;
        let totalTime = 0;
        for (let i = 0; i < this.intervalList.length; i++) {
          const element = this.intervalList[i];
          element.taskIds = this.taskList.map((e) => e.taskIdStr);
          element.taskIdStrs = this.taskList.map((e) => e.taskIdStr);
          if (i == 0) startTime = element.startTime;
          if (i == this.intervalList.length - 1) endTime = element.endTime;
          totalTime += element.endTime - element.startTime;
        }
        let updateTime = endTime;
        let params = {
          absorbedInfoList: this.intervalList,
          complete: false,
          completeIds: [],
          completeIdStrs: [],
          endTime,
          mode: this.tab == "normal" ? 1 : 2,
          startTime,
          totalTime,
          updateTime,
        };
        absorbedAdd(params).then((res) => {
          if (res) {
            this.$Message.success("恭喜，已完成本次专注");
          }
        });
      }
      this.seconds = 0;
      this.isBegin = false;
      this.isPause = false;
      this.intervalList = [];
      clearInterval(this.interval);
    },
    pasuseClick() {
      if (this.isPause) {
        this.isPause = false;
        this.insertStartTime();
        if (this.tab == "normal") {
          this.normalInterval();
        } else {
          this.reverseInterval();
        }
        this.isBegin = true;
      } else {
        this.isPause = true;
        this.insertEndTime();
        clearInterval(this.interval);
      }
    },
    timeChange(value) {
      if (!value || value.length == 0) {
        this.originSeconds = 0;
      } else {
        let l = value.split(":");
        this.originSeconds = parseInt(l[0]) * 60 * 60 + parseInt(l[1]) * 60;
      }
    },
    onSelected(taskList) {
      this.taskList = taskList;
    },
    onRelateClick() {
      if (!this.isBegin) {
        this.searchShow = true;
      }
    },
    // 点击开始或者重启添加开始时间
    insertStartTime() {
      let nowTime = new Date().getTime();
      this.intervalList.push({
        startTime: nowTime,
      });
    },
    insertEndTime() {
      let nowTime = new Date().getTime();
      if (this.intervalList.length > 0) {
        let obj = this.intervalList[this.intervalList.length - 1];
        if (!obj.endTime) obj.endTime = nowTime;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.timer {
  border-radius: 8px;
  background: #ffffffff;
  // width: 200px;
  :deep(.ivu-tabs-bar) {
    display: flex;
    justify-content: center;
  }
  .related {
    border-radius: 4px;
    background: #fafafaff;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    margin-bottom: 10px;
    font-size: 14px;
    .delete {
      display: none;
    }
    &:hover {
      .delete {
        display: inline;
      }
    }
  }
}
</style>
