<template>
  <div style="position: relative;">
    <!-- <div id="xMap"></div> -->
    <Select
      style="position: absolute; top: 8px; width: calc(100% - 40px); left: 20px; height: 48px; border-radius: 8px;"
      clearable
      filterable
      placeholder="请输入地址"
      :remote-method="addressSearch"
      :loading="loading">
      <Option v-for="(address, index) in addressList" :value="address.id" :key="index" @click.stop="onOptionClick($event, address)">
        <span style="font-size: 14px; color: black;">{{ address.name }}</span>
        <span style="font-size: 12px; color: #A6A6A6; margin-left: 12px;">{{ address.address }}</span>
      </Option>
      <template #prefix>
        <XIcon style="margin-top: 14px;" iconClass="search_gray" />
      </template>
    </Select>
    <div style="position: absolute; top: 50%; left: 50%; margin-left: -15px; margin-top: -15px;">
      <XIcon size="31" iconClass="location_red" />
    </div>
    <div class="address-box">
      <p style="font-size: 14px; color: black;">
        <XIcon iconClass="location" color="#999999" style="margin-top: -2px;" />
        {{ centerAddress.name }}
      </p>
      <p style="font-size: 12px; color: #A6A6A6; margin-top: 4px; margin-left: 20px;">{{ centerAddress.address }}</p>
    </div>
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import AMapLoader from '@amap/amap-jsapi-loader';

window._AMapSecurityConfig = {
  securityJsCode: 'fca64ad19c7284e54170f226a8fb1dd6'
}

export default {
  setup() {
    const map = shallowRef(null)
    return{
      map,
    }
  },
  mounted() {
    this.initMap()
  },
  data() {
    return {
      mapSearch: {},

      loading: false,
      addressList: [],
      centerAddress: {},
      geocoder: {}
    }
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: '7edc1c485135bc102648eb8ef9f7e18e',
        version: '2.0',
        plugins: [
          "AMap.Scale", //工具条，控制地图的缩放、平移等
          "AMap.ToolBar", //比例尺，显示当前地图中心的比例尺
          'AMap.Geocoder', // 逆地理编码
          "AMap.PlaceSearch"
        ],
        // AMapUI:{
        //     version:"1.1",
        //     plugins:[],
        // },
        // Loca:{
        //     version:"2.0.0"
        // },
      }).then((AMap) => {
        this.map = new AMap.Map("xMap",{
          viewMode: "3D",
          zoom: 12,
          zooms: [2,22],
        });

        this.mapSearch = new AMap.PlaceSearch();

        this.map.setDefaultCursor("pointer"); //使用CSS默认样式定义地图上的鼠标样式（default/pointer/move/crosshair）
        this.map.addControl(new AMap.Scale()); //异步同时加载多个插件
        this.map.addControl(new AMap.ToolBar());

        this.geocoder = new AMap.Geocoder();
        // this.map.on("click", function (e) {
        //   var lnglat = [e.lnglat.lng, e.lnglat.lat];
        //   geocoder.getAddress(lnglat, function(status, result) {
        //     console.log(result);
        //     if (status === 'complete' && result.info === 'OK') {
        //       that.$emit('onSelected', {
        //         lng: e.lnglat.lng,
        //         lat: e.lnglat.lat,
        //         address: result.regeocode.formattedAddress
        //       });
        //     }
        //   })
        // });

        this.map.on("moveend", this.onMoveEnd);
        this.onMoveEnd();

        let positionArr = [
          // [113.357224,34.977186],
          // [114.555528,37.727903],
          // [112.106257,36.962733],
          // [109.830097,31.859027],
          // [116.449181,39.98614],
          // [116.449181,39.98614],
        ];
        for(let item of positionArr){
          let textMarker = new AMap.Text({
            position:[item[0],item[1]],
            text: '任务11234',
            style:{
              'padding': '.75rem 1.25rem',
              'border-radius': '.25rem',
              'background-color': 'white',
              'border-width': 0,
              'box-shadow': '0 2px 6px 0 rgba(0, 0, 0, .5)',
              'text-align': 'center',
              'font-size': '15px',
            }
          });
          this.map.add(textMarker)
        }
      }).catch(e => {
        console.log(e)
      })
    },
    onMoveEnd() {
      var center = this.map.getCenter();
      var lnglat = [center.lng, center.lat];
      let that = this;
      this.geocoder.getAddress(lnglat, function(status, result) {
        if (status === 'complete' && result.info === 'OK') {
          let name = '';
          let component = result.regeocode.addressComponent;
          if (component.building.length > 0) {
            name = component.building;
          } else if (component.neighborhood.length > 0) {
            name = component.neighborhood;
          } else {
            let c = component.province + component.city + component.district + component.township;
            name = result.regeocode.formattedAddress.replace(c, '');
          }
          that.centerAddress = {
            lng: center.lng,
            lat: center.lat,
            name,
            address: result.regeocode.formattedAddress
          }
        }
      })
    },
    addressSearch(query) {
      if (query != '') {
        this.loading = true;
        this.mapSearch.search(query, (status, result) => {
          this.loading = false;
          if (status == 'complete' && result['info'] == 'OK') {
            this.addressList = result.poiList.pois;
          }
        }); 
      }
    },
    onOptionClick(event, address) {
      event.preventDefault();
      let position = [address.location.lng, address.location.lat];
      this.map.setCenter(position);
    }
  }
}
</script>

<style lang="less" scoped>
  #xMap {
    width: 100%;
    height: 100%;
    min-height: 300px;
    min-width: 300px;
  }
  :deep(.ivu-select-selection) {
    border-radius: 8px;
    height: 48px !important;
  }
  :deep(.ivu-select-input) {
    top: 7px !important;
  }
  .address-box {
    padding: 6px 20px;
    margin: 3px;
  }
</style>
