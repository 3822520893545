<template>
  <div class="third-verify">
    <XIcon iconClass="qq_login" size="40" @click="qqLogin"></XIcon>
    <XIcon style="margin: 0 20px;" iconClass="weibo_login" size="40" @click="weiboLogin"></XIcon>
    <XIcon iconClass="wechat_login" size="40" @click="wechatLogin"></XIcon>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    qqLogin() {
      window.location = 'https://graph.qq.com/oauth2.0/authorize?client_id=102034174&response_type=code&redirect_uri=https%3A%2F%2Fwww.yimutodo.com%2F%23%2FloginCheck&state=QQ';
    },
    weiboLogin() {
      window.location = 'https://api.weibo.com/oauth2/authorize?client_id=2718249193&response_type=code&redirect_uri=https%3A%2F%2Fwww.yimutodo.com%2F%23%2FloginCheck';
    },
    wechatLogin() {
      this.$router.push({name: 'wxlogin'});
    }
  }
}
</script>

<style lang="less" scoped>
  .third-verify {
    display: flex;
    justify-content: center;
  }
</style>