import { createRouter, createWebHashHistory } from "vue-router"

import XLogin from "@/pages/XLogin"
import XWXLogin from "@/pages/XWXLogin"
// import XRegister from "@/pages/XRegister"
// import XReset from '@/pages/XReset'
import XHome from "@/pages/XHome"
import XPrimary from "@/pages/XPrimary"
import XManager from "@/pages/XManager"
import XWork from "@/pages/XWork"
import XRecycler from '@/pages/XRecycler'
// import XTask from "@/components/XTask"
import XCalendar from '@/pages/XCalendar'
import XGroup from '@/pages/XGroup'

import XMap from "@/components/XMap"
import XCalendarComponent from "@/components/XCalendarComponent"
import XTimerComponent from "@/components/XTimerComponent"

import XException from "@/pages/XException"
import XTest from "@/pages/XTest"

import XContact from '@/pages/XContact'
import XLoginCheck from '@/pages/XLoginCheck'

import XSetting from '@/pages/XSetting'
import XMember from '@/pages/XMember'
import XTickets from '@/pages/XTickets'
import XAbout from '@/pages/XAbout'
import XAccount from '@/pages/XAccount'
import XTheme from '@/pages/XTheme'
import XSmart from '@/pages/XSmart'
import XFloat from '@/pages/XFloat'
import XNotice from "@/pages/XNotice";
import XPreferences from "@/pages/XPreferences";

import { noticeArrive } from '@/common/api'

const routes = [{
  path: '/',
  component: XHome,
  children: [{
    path: '',
    name: 'primary',
    component: XPrimary,
  }, {
    path: 'home',
    name: 'home',
    component: XPrimary,
  }]
}, {
  path: '/contact',
  name: 'contact',
  component: XContact,
}, {
  path: '/calendarComponent',
  name: 'calendarComponent',
  component: XCalendarComponent,
}, {
  path: '/timerComponent',
  name: 'timerComponent',
  component: XTimerComponent,
}, {
  path: '/manager',
  name: 'manager',
  component: XManager,
  redirect: '/manager/work',
  children: [{
    path: 'work/:project?/:task?',
    name: 'work',
    component: XWork
  }, {
    path: 'calendar/:type?',
    name: 'calendar',
    component: XCalendar
  }, {
    path: 'group/:item',
    name: 'group',
    component: XGroup,
  }, {
    path: 'recycler',
    name: 'recycler',
    component: XRecycler
  }
  // {
  //   path: 'task/:type',
  //   name: 'task',
  //   component: XTask,
  // }
]
}, {
  path: '/map',
  name: 'map',
  component: XMap,
}, {
  path: '/login',
  name: 'login',
  component: XLogin,
}, {
  path: '/wxlogin',
  name: 'wxlogin',
  component: XWXLogin,
}, {
  path: '/register',
  name: 'register',
  component: XLogin,
}, {
  path: '/reset',
  name: 'reset',
  component: XLogin,
}, {
  path: '/preferences',
  name: 'preferences',
  component: XPreferences,
}, {
  path: '/notices',
  name: 'notices',
  component: XNotice,
}, {
  path: '/settings',
  name: 'settings',
  component: XSetting,
  // redirect: '/settings/account',
  children: [{
    path: 'account',
    name: 'account',
    component: XAccount
  }, {
    path: 'member',
    name: 'member',
    component: XMember,
  }, {
    path: 'tickets',
    name: 'tickets',
    component: XTickets,
  }, {
    path: 'theme',
    name: 'theme',
    component: XTheme,
  }, {
    path: 'smart',
    name: 'smart',
    component: XSmart,
  }, {
    path: 'about',
    name: 'about',
    component: XAbout,
  }]
}, {
  path: '/loginCheck',
  name: 'loginCheck',
  component: XLoginCheck,
}, {
  path: '/float',
  name: 'float',
  component: XFloat,
}, {
  path: '/test',
  name: 'test',
  component: XTest,
}, {
  path: '/404',
  name: '404',
  component: XException,
}, {
  path: "/:catchAll(.*)", // 不识别的path自动匹配404
  redirect: '/404',
}]

const router = createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(),
  routes: routes
})

router.beforeEach((to,from,next)=>{
  noticeArrive();
  next();  
})

export default router