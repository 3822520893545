<template>
  <div class="setting">
    <Button class="setting-btn back" type="default" size="large" @click="onBackClick">
      <Icon type="md-arrow-back" />&nbsp;&nbsp;返回
    </Button>
    <div class="setting-content">
      <div style="height: 80px;"></div>
      <h5 class="title">偏好设置</h5>


      <div class="setting-cell">
        <div>
          <p>周起始日</p>
          <p><Icon type="ios-information-circle" style="color: #42B4FF;" />根据你的习惯设置一周起始日</p>
        </div>
        <Dropdown>
          <div>
            <span style="margin-right: 25px">
              {{ weekStart }}
            </span>
            <Icon type="ios-arrow-down" color="#999999" size="16"/>
          </div>
          <template #list>
            <DropdownMenu >
              <DropdownItem name="0" @click="clickWeekStart(0)">
                <div style="text-align: center">周日</div>
              </DropdownItem>
              <DropdownItem name="6" @click="clickWeekStart(6)">
                <div style="text-align: center">周六</div>
              </DropdownItem>
              <DropdownItem name="1" @click="clickWeekStart(1)">
                <div style="text-align: center">周一</div>
              </DropdownItem>
            </DropdownMenu>
          </template>
        </Dropdown>
      </div>
      <div class="setting-cell">
        <div>
          <p>父任务自动完成</p>
          <p><Icon type="ios-information-circle" style="color: #42B4FF;" />当完成所有的子任务后，自动完成父任务</p>
        </div>
        <Switch v-model="isAuto" :before-change="changeAutoComplete" />
      </div>
      <div v-if="isElectron" class="setting-cell">
        <div>
          <p>客户端启用/隐藏快捷键</p>
          <p><Icon type="ios-information-circle" style="color: #42B4FF;" />自定义设置快捷键用于打开和隐藏本软件</p>
        </div>
        <div @click="changeKey">
            <span style="margin-right: 25px">
              {{ shortKey }}
            </span>
          <Icon type="ios-arrow-down" color="#999999" size="16"/>
        </div>
      </div>
      <div class="setting-cell">
        <div>
          <p>新建任务的快捷键</p>
          <p><Icon type="ios-information-circle" style="color: #42B4FF;" />自定义设置快捷键用于在列表中创建任务，适用于父任务及其子任务</p>
        </div>
        <Dropdown>
          <div>
            <span style="margin-right: 25px">
              {{ newTaskValue }}
            </span>
            <Icon type="ios-arrow-down" color="#999999" size="16"/>
          </div>
          <template #list>
            <DropdownMenu >
              <DropdownItem name="0" @click="newTaskKeyClick('Enter')">
                <div style="text-align: center">回车</div>
              </DropdownItem>
              <DropdownItem name="6" @click="newTaskKeyClick('Tab')">
                <div style="text-align: center">Tab</div>
              </DropdownItem>
            </DropdownMenu>
          </template>
        </Dropdown>
      </div>
      <!-- <div class="setting-cell">
        <div>
          <p>模式切换</p>
          <p><Icon type="ios-information-circle" style="color: #42B4FF;" />深浅色模式切换，优化视觉体验和眼睛舒适度</p>
        </div>
        <Dropdown>
          <div>
            <span style="margin-right: 25px">
              <Icon type="ios-color-palette-outline" size="24"/>
              {{ themeMap[theme] }}
            </span>
            <Icon type="ios-arrow-down" color="#999999" size="16"/>
          </div>
          <template #list>
            <DropdownMenu >
              <DropdownItem name="0" @click="themeChange('light')">
                <div style="text-align: center"><Icon type="ios-color-palette-outline" size="16"/> 浅色</div>
              </DropdownItem>
              <DropdownItem name="6" @click="themeChange('dark')">
                <div style="text-align: center"><Icon type="ios-color-palette" size="16"/> 深色</div>
              </DropdownItem>
            </DropdownMenu>
          </template>
        </Dropdown>
      </div> -->
    </div>
  </div>
  <Modal :mask="false" v-model="showNickModal" width="420px" @on-ok="onNickModalOk">
    <p style="color: #000; font-size: 16px;">修改昵称</p>
    <Input 
      style="margin: 24px 0px 30px 0px;" 
      v-model="nickName"
      placeholder="请输入昵称" />
  </Modal>
  <Modal :mask="false" v-model="showAvatarModal" width="420px" @on-ok="onAvatarModalOk">
    <p style="color: #000; font-size: 16px;">修改头像</p>
    <div style="display: flex;">
      <div style="width: 230px; height: 230px; margin: 24px 30px 0px 0px;">
        <vueCropper
          style="margin-bottom: 20px;"
          ref="cropper"
          :img="options.img"
          :autoCrop="options.autoCrop"
          :fixed="options.fixed"
          :centerBox="options.centerBox"
          @realTime="realTime"
        ></vueCropper>
        <Upload
          ref="upload"
          action=""
          :show-upload-list="false"
          accept="image/png, image/jpeg, image/jpg"
          :format="['jpg','jpeg','png']"
          :on-format-error="handleFormatError"
          :on-exceeded-size="handleMaxSize"
          :before-upload="handleBeforeUpload" >
          <span class="primary-color hand">{{ options.img.length > 0 ? '重新上传' : '选择图片' }}</span>
        </Upload>
      </div>
      <div style="width: 90px; margin-top: 24px;">
        <div v-if="previewInfo.url" :style="previewStyle"> 
          <div :style="previewInfo.div">
            <Image :src="previewInfo.url" :style="previewInfo.img" />
          </div>
        </div>
        <Image v-else-if="userInfo.avatarUrl" :src="userInfo.avatarUrl" with="90" style="border-radius: 45px; overflow: hidden;" />
        <p style="margin-top: 20px; color: #000; font-size: 14px; text-align: center;">头像预览</p>
      </div>
    </div>
  </Modal>
  <Modal :mask="false" v-model="showEmailModal" width="420px" @on-ok="onEmailModalOk">
    <p style="color: #000; font-size: 16px;">绑定邮箱</p>
    <Form style="margin-top: 50px;" ref="form" :model="formdata" :rules="rules" label-position="left" :label-width="80">
      <FormItem prop="email" label="邮箱">
        <Input :border="false" size="large" type="email" v-model="formdata.email" placeholder="请输入邮箱"></Input>
      </FormItem>
      <FormItem prop="captcha" label="验证码">
        <Input style="flex: 1;" :border="false" size="large" type="text" v-model="formdata.captcha" placeholder="请输入验证码">
          <template #suffix>
            <Button type="text" class="captcha-button" @click="captchaClick(0)">{{ seconds == 60 ? '获取验证码' : seconds }}</Button>
          </template>
        </Input>
      </FormItem>
      <FormItem prop="password" label="密码">
        <Input :border="false" size="large" :type="showPassword1 ? 'text' : 'password'" v-model="formdata.password" placeholder="请输入密码,6-20个字符">
          <template #suffix>
            <div class="hand" @click="(showPassword1=!showPassword1)">
              <Icon v-if="showPassword1" style="line-height: 48px; color: #000;" type="md-eye" />
              <XIcon v-else iconClass="eye" size="16" style="margin: 16px 0px;" />
            </div>
          </template>
        </Input>
      </FormItem>
      <FormItem prop="password" label="确认密码">
        <Input :border="false" size="large" :type="showPassword2 ? 'text' : 'password'" v-model="formdata.passwordConfirm" placeholder="请确认密码">
          <template #suffix>
            <div class="hand" @click="(showPassword2=!showPassword2)">
              <Icon v-if="showPassword2" style="line-height: 48px; color: #000;" type="md-eye" />
              <XIcon v-else iconClass="eye" size="16" style="margin: 16px 0px;" />
            </div>
          </template>
        </Input>
      </FormItem>
    </Form>
  </Modal>
  <Modal :mask="false" v-model="showEmailUnbindModal" width="420px" @on-ok="onEmailUnbindModalOk">
    <p style="color: #000; font-size: 16px;">解除绑定</p>
    <Form style="margin-top: 50px;" ref="unbindForm" :model="formdata" :rules="unbindRules" label-position="left" :label-width="80">
      <FormItem prop="email" label="邮箱">
        <Input readonly :border="false" size="large" type="email" v-model="formdata.email" placeholder="请输入邮箱"></Input>
      </FormItem>
      <FormItem prop="captcha" label="验证码">
        <Input style="flex: 1;" :border="false" size="large" type="text" v-model="formdata.captcha" placeholder="请输入验证码">
          <template #suffix>
            <Button type="text" class="captcha-button" @click="captchaClick(2)">{{ seconds == 60 ? '获取验证码' : seconds }}</Button>
          </template>
        </Input>
      </FormItem>
    </Form>
  </Modal>
  <Modal :mask="false" v-model="showModifyModal" width="420px" @on-ok="onModifyModalOk">
    <p style="color: #000; font-size: 16px;">修改密码</p>
    <Form style="margin-top: 50px;" ref="form" :model="formdata" :rules="modifyRules" label-position="left" :label-width="80">
      <FormItem prop="password" label="当前密码">
        <Input :border="false" size="large" :type="showPassword1 ? 'text' : 'password'" v-model="formdata.password" placeholder="请输入当前密码">
          <template #suffix>
            <div class="hand" @click="(showPassword1=!showPassword1)">
              <Icon v-if="showPassword1" style="line-height: 48px; color: #000;" type="md-eye" />
              <XIcon v-else iconClass="eye" size="16" style="margin: 16px 0px;" />
            </div>
          </template>
        </Input>
      </FormItem>
      <FormItem prop="password" label="新密码">
        <Input :border="false" size="large" :type="showPassword2 ? 'text' : 'password'" v-model="formdata.passwordConfirm" placeholder="请输入新密码">
          <template #suffix>
            <div class="hand" @click="(showPassword2=!showPassword2)">
              <Icon v-if="showPassword2" style="line-height: 48px; color: #000;" type="md-eye" />
              <XIcon v-else iconClass="eye" size="16" style="margin: 16px 0px;" />
            </div>
          </template>
        </Input>
      </FormItem>
    </Form>
  </Modal>
  <Modal :mask="false" v-model="showThirdUnbindModal" width="420px" @on-ok="onThirdUnbindModalOk">
    <p style="color: #000; font-size: 16px;">解除绑定</p>
    <p style="margin: 20px 0px 50px 0px; font-size: 14px;">解除绑定后，将无法使用{{ typeKeys[unbindInfo.type] }}账号：“{{ unbindInfo.openNickName }}”登录并关联当前账号。<br/>确定要解除绑定吗？</p>
  </Modal>
  <Modal :mask="false" v-model="showErrorUnbindModal" width="420px">
    <p style="color: #000; font-size: 16px;">解除绑定</p>
    <p style="margin: 20px 0px 50px 0px; font-size: 14px;">当前{{ typeKeys[unbindInfo.type] }}账号为初始注册账户，无法进行解绑，如要彻底删除，请点击底部注销账号。</p>
  </Modal>
  <Modal :mask="false" v-model="showDestroyModal" width="420px" @on-ok="onDestroyModalOk">
    <p style="color: #000; font-size: 16px;">注销账号</p>
    <p style="margin: 20px 0px; color: #000; font-size: 14px;">
      该操作将会永久删除当前账号，以及账号相关的所有数据。注销后账号无法再次找回。<br/>
      如您确认要注销，请输入以下确认码：
    </p>
    <p style="margin-bottom: 20px; font-size: 16px; color: #000; text-align: center;">{{ confirmCode }}</p>
    <Input 
      :maxlength="6"
      style="margin-bottom: 50px;"
      v-model="confirmInput"
      placeholder="请输入确认码" />
  </Modal>

  <Modal :mask="false" v-model="showWechatModal" width="420px" footer-hide>
    <p style="color: #000; font-size: 16px;">绑定微信</p>
    <div style="display: flex;align-items: center;justify-content: center;margin-top: 24px;">
      <Image src="" style="width: 180px;height: 180px;background:rgba(204, 204, 204, 1);"/>
    </div>
    <p style="color: #000; font-size: 18px;margin-top: 30px;text-align: center">使用微信扫描二维码</p>
    <p style="color: #000; font-size: 14px;margin-top: 16px;text-align: center">使用微信扫描二维码，登录一木清单</p>
  </Modal>


  <Modal :mask="false" v-model="showKeyModal" width="420px" @on-ok="onKeyEditModalOk">
    <p style="color: #000; font-size: 16px;">修改快捷键</p>
    <div style="display: flex;align-items: center;margin-top: 20px;" >
      快捷键
      <Input
          readonly
          style="flex:1;margin-left: 16px"
          v-model="inputStr"
          placeholder="请直接点按键盘按键进行设置快捷键" />
    </div>

  </Modal>
</template>

<script>
let isElectron = process.env.IS_ELECTRON;

import {
  userInfo,
  modifyInfo,
  ossUpload,
  captcha,
  bindEmail,
  unbindEmail,
  unbindThird,
  userDestroy,
  modifyPassword,
  modifyConfigInfo,
  userConifgInfo, channelEvent
} from '@/common/api';
import 'vue-cropper/dist/index.css'
import { VueCropper }  from "vue-cropper";
import { useMagicKeys } from '@vueuse/core'
const { current } = useMagicKeys({
  // passive: false,
  // onEventFired(e) {
  //   e.preventDefault()
  //   return false
  // },
})

export default {
  components: {
    VueCropper
  },
  data() {
    const validatePassCheck = (rule, value, callback) => {
      if (value !== this.$refs.form.formValidate.password) {
        callback(new Error('两次输入的密码不匹配！'));
      } else {
        callback();
      }
    };
    return {
      isElectron,
      theme: 'light',
      themeMap: {
        dark: '深色',
        light: '浅色'
      },
      typeKeys: {
        'QQ': 'QQ',
        'WECHAT': '微信',
        'WEIBO': '微博',
        'EMAIL': '邮箱'
      },
      confirmCode: '123456',
      confirmInput: '',

      showNickModal: false,
      nickName: '',

      options: {
        img: '', // 原图文件
        autoCrop: true, // 默认生成截图框
        fixedBox: false, // 固定截图框大小
        canMoveBox: true, // 截图框可以拖动
        autoCropWidth: 180, // 截图框宽度
        autoCropHeight: 180, // 截图框高度
        fixed: true, // 截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        centerBox: true, // 截图框被限制在图片里面
        canMove: false, // 上传图片不允许拖动
        canScale: false // 上传图片不允许滚轮缩放
      },
      previewStyle: '',
      previewInfo: {},
      showAvatarModal: false, 

      seconds: 60,
      showEmailModal: false,
      formdata: {},
      showPassword1: false,
      showPassword2: false,
      showModifyModal: false,
      rules: {
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { type: 'string', min: 6, max: 20, message: '请输入六到二十位的密码', trigger: 'blur' }
        ],
        passwordConfirm: [
          { required: true, message: '请确认密码', trigger: 'blur' },
          { validator: validatePassCheck, trigger: 'blur' }
        ],
      },
      unbindRules: {
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
      },
      modifyRules: {
        password: [
          { required: true, message: '请输入当前密码', trigger: 'blur' },
        ],
        passwordConfirm: [
          { required: true, message: '请确认密码', trigger: 'blur' },
          { type: 'string', min: 6, max: 20, message: '请输入六到二十位的新密码', trigger: 'blur' }
        ],
      },

      unbindInfo: {},
      showThirdUnbindModal: false,
      showEmailUnbindModal: false,
      showErrorUnbindModal: false,
      showWechatModal: false,

      showDestroyModal: false,
      showEmailEditModal: false,
      configs: [],
      customEmail: '',
      inputStr: '',
      weekBegin: 0,
      isAuto: false,
      shortKey: localStorage.getItem('shortKey') || 'control/command+y',
      showKeyModal: false,
      newTaskKey: 'Enter'
    }
  },
  async mounted() {
    if (!this.userInfo.userId) {
      await this.loadInfo();
    } else {
      this.nickName = this.userInfo.nickName;
      this.customEmail = this.userInfo.email;
    }
    userConifgInfo().then(res=>{
      this.configs = res;
      const emailInfo = this.configs.find(item=>item.type == 'NOTICE_EMAIL_SWITCH');
      if (emailInfo && emailInfo.value!='false'){
        this.customEmail = emailInfo.value
      }
    })

    this.weekBegin = localStorage.getItem("weekBegin") || 0
    this.isAuto = (localStorage.getItem("autoComplete") || 0) == 0? false : true
    this.newTaskKey = localStorage.getItem("newTaskKey") || 'Enter'
    this.newTaskKey = localStorage.getItem('theme-appearance') || 'light'
  },
  watch: {
    keys(newV){
        this.inputStr = newV
    }
  },
  computed: {
    keys(){
      let str = Array.from(current).join("+");
      return str
    },
    weekStart(){
      if(this.weekBegin == 0){
        return '周日'
      }else if(this.weekBegin == 6){
        return '周六'
      }else if(this.weekBegin ==1){
        return '周一'
      }else{
        return '周日'
      }
    },
    newTaskValue() {
      if (this.newTaskKey == 'Tab') {
        return 'Tab';
      }
      return '回车';
    },
    isWechatNotice() {
      const wechatInfo = this.configs.find(item=>item.type == 'NOTICE_WECHAT_SWITCH');
      return wechatInfo?.value == 'true'
    },
    isEmailNotice() {
      const emailInfo = this.configs.find(item=>item.type == 'NOTICE_EMAIL_SWITCH');
      if (emailInfo?.value){
        if (emailInfo?.value != 'false'){
          return true
        }
      }
      return false
    },
    isCalendarNotice() {
      const calendarInfo = this.configs.find(item=>item.type == 'NOTICE_CALENDAR_SWITCH');
      return calendarInfo?.value == 'true'
    },
    qqInfo() {
      if (!this.userInfo.openInfos || this.userInfo.openInfos.length == 0) return null;
      return this.userInfo.openInfos.find(item => item.type == 'QQ');
    },
    wechatInfo() {
      if (!this.userInfo.openInfos || this.userInfo.openInfos.length == 0) return null;
      return this.userInfo.openInfos.find(item => item.type == 'WECHAT');
    },
    weiboInfo() {
      if (!this.userInfo.openInfos || this.userInfo.openInfos.length == 0) return null;
      return this.userInfo.openInfos.find(item => item.type == 'WEIBO');
    },
    userInfo() {
      return this.$store.state.user;
    },
    memberTitle() {
      let memberType = this.userInfo.memberType;
      switch (memberType) {
        case 'NORMAL':
          return '普通用户';
        case 'MONTH':
          return '月度会员';
        case 'YEAR':
          return '年度会员';
        case 'PERMANENT':
          return '永久会员';
        default:
          return '普通用户';
      }
    },
  },
  methods: {
    themeChange(type){
      this.theme = type
      localStorage.setItem('theme-appearance', type)
    },
    clickWeekStart(name){
      this.weekBegin = name
      localStorage.setItem("weekBegin",name)
    },
    newTaskKeyClick(key) {
      this.newTaskKey = key
      localStorage.setItem("newTaskKey", key)
    },
    changeAutoComplete(){
      this.isAuto = !this.isAuto
      localStorage.setItem("autoComplete",this.isAuto?"1":"0")
    },
    changeKey(){
      this.showKeyModal = true
    },
    changeWechatNotice(){
      return new Promise((resolve => {
        modifyConfigInfo("NOTICE_WECHAT_SWITCH",!this.isWechatNotice).then(res=>{
          if (res){
            resolve();
          }
        })
      }))
    },
    changeEmailNotice(){
      return new Promise((resolve => {
        if (this.isEmailNotice){
          modifyConfigInfo("NOTICE_EMAIL_SWITCH","false").then(res=>{
            if (res){
              resolve();
            }
          })
        }else{
          modifyConfigInfo("NOTICE_EMAIL_SWITCH",this.customEmail).then(res=>{
            if (res){
              resolve();
            }
          })
        }
      }))
    },
    changeCalendarNotice(){
      return new Promise((resolve => {
        modifyConfigInfo("NOTICE_CALENDAR_SWITCH",!this.isCalendarNotice).then(res=>{
          if (res){
            resolve();
          }
        })
      }))
    },
    onEmailEditModalOk(){
      this.customEmail = this.inputStr;
      modifyConfigInfo("NOTICE_EMAIL_SWITCH",this.customEmail)
    },
    onChangeEmailClick() {
      this.inputStr = this.customEmail;
      this.showEmailEditModal = true;
    },
    onBackClick() {
      this.$router.go(-1);
    },
    onComplete() {
      this.$router.push('/manager/work');
    },
    onMenuSelect(name) {
      this.activeName = name;
      this.$router.push({name});
    },
    onNickClick() {
      this.showNickModal = true;
    },
    onNickModalOk() {
      if (this.nickName.length == 0) return;
      modifyInfo(this.nickName).then(res => {
        if (res) {
          this.userInfo.nickName = this.nickName;
        }
      });
    },
    onAvatarClick() {
      this.showAvatarModal = true;
    },
    handleFormatError() {
      this.$Message.error('请选择图片');
    },
    handleMaxSize() {
      this.$Message.error('图片最大为2M');
    },
    handleBeforeUpload(file) {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        this.options.img = e.target.result // base64
      }
    },
    realTime(data) {
      this.previewStyle = {
        width: data.w + "px",
        height: data.h + "px",
        overflow: "hidden",
        margin: "0",
        zoom: 100 / data.w,
        borderRadius: data.w / 2 + "px"
      }
      this.previewInfo = data;
    },
    onAvatarModalOk() {
      if (this.previewInfo.img) {
        this.$refs.cropper.getCropData(data => {
          let fileName = this.$store.state.user.userId + '/' + Date.now() + 'avatar.jpg';
          let file = this.blobToFile(this.dataURLtoBlob(data), fileName);
          ossUpload(fileName, file).then(res => {
            if (res) {
              this.options.img = '';
              this.previewStyle = {};
              this.previewInfo = {};

              this.userInfo.avatar = fileName;
              this.userInfo.avatarUrl = res.url;
              modifyInfo(null, fileName);
            }
          });
        });
      }
    },
    // base64转Blob
    dataURLtoBlob(dataurl) { 
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    // Blob转file
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return theBlob;
    },
    onModifyClick() {
      this.showPassword1 = false;
      this.showPassword2 = false;
      this.formdata = {};
      this.showModifyModal = true;
    },
    onEmailClick() {
      this.showPassword1 = false;
      this.showPassword2 = false;
      this.formdata = {};
      this.showEmailModal = true;
    },
    onWechatClick() {
      if (this.wechatInfo) {
        if (this.wechatInfo.type == this.userInfo.registerType) {
          this.showErrorUnbindModal = true;
        } else {
          this.unbindInfo = this.wechatInfo;
          this.showThirdUnbindModal = true;
        }
      } else {
        this.$Message.info('抱歉，暂时需要前往APP绑定第三方账号~');
      }
    },
    onQQClick() {
      if (this.qqInfo) {
        if (this.qqInfo.type == this.userInfo.registerType) {
          this.showErrorUnbindModal = true;
        } else {
          this.unbindInfo = this.qqInfo;
          this.showThirdUnbindModal = true;
        }
      } else {
        this.$Message.info('抱歉，暂时需要前往APP绑定第三方账号~');
      }
    },
    onWeiboClick() {
      if (this.weiboInfo) {
        if (this.weiboInfo.type == this.userInfo.registerType) {
          this.showErrorUnbindModal = true;
        } else {
          this.unbindInfo = this.weiboInfo;
          this.showThirdUnbindModal = true;
        }
      } else {
        this.$Message.info('抱歉，暂时需要前往APP绑定第三方账号~');
      }
    },
    onKeyEditModalOk(){
      if(this.inputStr){
        this.shortKey = this.inputStr
        localStorage.setItem('shortKey', this.shortKey);
        setTimeout(()=>{
          channelEvent('shortKey', this.shortKey);
        },1000)
      }
    },
    onEmailModalOk() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          bindEmail(this.formdata.email, this.formdata.password, this.formdata.captcha).then(res => {
            if (res) {
              this.userInfo.email = this.formdata.email;
            }
          });
        }
      })
    },
    captchaClick(type = 0) {
      if (this.seconds < 60) return;
      this.$refs['form'].validateField('email', (valid) => {
        if (!valid) {
          captcha(this.formdata.email, type);
          var interval = setInterval(() => {
            if (this.seconds == 1) {
              this.seconds = 60;
              clearInterval(interval);
            } else {
              this.seconds--;
            }
          }, 1000);
        }
      });
    },
    onModifyModalOk() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          modifyPassword(this.formdata.password, this.formdata.passwordConfirm).then(res => {
            if (res) {
              this.$Message.success('修改密码成功~');
            }
          });
        }
      })
    },
    onEmailUnbindModalOk() {
      unbindEmail(this.formdata.captcha).then(res => {
        if (res) {
          this.loadInfo();
        }
      });
    },
    onThirdUnbindModalOk() {
      unbindThird(this.unbindInfo.type, this.unbindInfo.openId).then(res => {
        if (res) {
          this.loadInfo();
        }
      });
    },
    async loadInfo() {
      const res = await userInfo()
      this.$store.commit("updateInfo", res);
      this.nickName = this.userInfo.nickName;
    },
    onDestroyClick() {
      let random = Math.random() * (999999 - 100000) + 100000;
      this.confirmCode = Math.floor(random);
      this.showDestroyModal = true;
    },
    onDestroyModalOk() {
      if (this.confirmCode == this.confirmInput) {
        userDestroy().then(res => {
          if (res) {
            this.$router.replace({name: 'login'});
          }
        });
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .setting {
    width: 100%;
    height: 100%;
    background-color: var(--fc-page-bg-color);
    position: relative;
    .setting-btn {
      border-radius: 8px;
      font-size: 14px;
      &.back {
        position: absolute;
        top: 24px;
        left: 24px;
      }
      &.high {
        color: #FF8F8F;
        border-color: #FF8F8F;
      }
    }
    .setting-content {
      height: calc(100% - 120px);
      max-width: 1024px;
      margin: 0px auto;
      // box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
      background-color: #fff;
      border-radius: 4px;
      .base-info {
        margin-top: 30px;
        font-weight: 500;
        font-size: 20px;
      }
      .member-tip {
        margin-top: 20px;
        text-align: center;
        width: 100%;
        height: 60px;
        border-radius: 8px;
        line-height: 60px;
        color: #666666;
        background: linear-gradient(135deg, rgba(219, 242, 255, 1) 0%, rgba(249, 235, 255, 1) 100%);
      }
      .setting-cell {
        border-bottom: 1px solid #F0F0F1; 
        display: flex;
        padding: 30px 0;
        align-items: center;
        div:first-child {
          flex: 1;
          p:first-child {
            font-size: 14px;
            color: #000;
            font-weight: 500;
            margin-bottom: 8px;
          }
          p:last-child {
            font-size: 14px;
            color: #666666;
            i:first-child {
              margin-right: 4px;
            }
          }
        }
      }
      .account {
        .attach {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  :deep(.ivu-dropdown) {
    // display: flex;
    // align-items: center;
    cursor: pointer;
  }
  :deep(.ivu-input) {
    border-radius: 8px;
    background-color: #F6F6F6;
    height: 48px;
  }
  :deep(.ivu-form-item-label) {
    height: 48px;
    line-height: 28px !important;
  }
  :deep(.ivu-input-suffix) {
    width: 102px;
  }
</style>