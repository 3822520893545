<template>
  <Modal 
    :mask="false" 
    :closable="false"
    v-model="showModal"
    @on-ok="onModalOk">
    <Input 
      class="title" 
      v-model="search" 
      size="large"
      :border="false" 
      placeholder="输入关键字搜索任务" 
      clearable
      @on-change="onChange" />
    <div v-if="realList.length > 0" style="height: 300px; overflow-y: scroll;">
      <div
        style="display: flex; align-items: center;"
        v-for="(item, index) in realList" 
        :key="item + index" 
        @click="onTaskClick(item)">
        <Icon 
          v-if="onSelected"
          :type="selectedList.indexOf(item) > -1 ? 'ios-checkmark-circle' : 'md-radio-button-off'" 
          class="primary-color" 
          style="font-size: 16px;" 
        />
        <XTaskNode 
          :ignore="true"
          :data="item"
          style="margin: 0px; flex: 1;"/>
      </div>
    </div>
		<!-- <div v-if="realList.length > 0" style="height: 300px; overflow-y: scroll;">
      <div>

        <XTaskNode 
        :ignore="true"
        style="margin: 0px;"
        v-for="(item, index) in realList" 
        :key="item + index" 
        :data="item"
        @click="onTaskClick(item)" />
      </div>
    </div> -->
    <div v-else style="text-align: center; height: 300px;">
      <XIcon iconClass="no_task" style="font-size: 150px; margin-top: 40px;" />
      <p style="font-size: 12px;">没有找到你想要的</p>
    </div>
    <Modal
      v-if="showTaskModal"
      :mask-closable="false"
      :scrollable="true"
      width="520px"
      :closable="false"
      v-model="showTaskModal">
      <XToastTaskDetail :task="task" />
    </Modal>
	</Modal>
</template>

<script>
  import XTaskNode from "@/components/XTaskNode";
  import XToastTaskDetail from "@/components/XToastTaskDetail"
  import { 
    userInfo,
    taskList,
  } from '@/common/api';

	export default {
		props: ['modelValue', 'onSelected'],
		emits: ['update:modelValue'],
    components: {
      XTaskNode,
      XToastTaskDetail
    },
    provide() {
      return {
        onMenuClick: () => {},
      }
    },
		mounted() {
      this.loadData();
		},
		data() {
			return {
				showModal: false,
				search: '',
        dataList: [],

        task: null,
        showTaskModal: false,
        selectedList: []
			}
		},
    computed: {
      realList() {
        let list = [];
        this.dataList.forEach((element) => {
          if (element?.title?.indexOf(this.search) > -1) {
            list.push(element);
          }
        });
        return list;
      }
    },
    methods: {
      onChange(value) {
        console.log(value);
      },
      loadData() {
        userInfo().then(info => {
          if (info && info.userId) {
            this.$store.commit("updateInfo", info);
            let projectId = '-2' + info.userId;
            taskList(projectId).then(res => {
              if (res) {
                this.completeList = [];
                this.normalList = [];
                res.forEach(element => {
                  if (element.completeTime > 0 || element.giveUp) {
                    this.completeList.push(element);
                  } else {
                    this.normalList.push(element);
                  }
                });
                this.dataList = this.normalList.concat(this.completeList);
              }
            });
          }
        });
      },
      onTaskClick(task) {
        if (this.onSelected) {
          if (this.selectedList.indexOf(task) > -1) {
            this.selectedList.splice(this.selectedList.indexOf(task), 1)
          } else {
            this.selectedList.push(task);
          }
        } else {
          this.showTaskModal = true;
          this.task = task;
        }
      },
      onModalOk() {
        if (this.onSelected) {
          this.onSelected(this.selectedList);
        }
      }
    },
		watch: {
			modelValue() {
				this.showModal = this.modelValue;
			},
			showModal() {
        if (this.showModal) {
          this.search = '';
          this.selectedList = [];
          this.loadData();
        }
				this.$emit('update:modelValue', this.showModal);
			}
		}
	}
</script>

<style lang="less" scoped>
  .title:deep(.ivu-input) {
    margin-bottom: 9px;
    border-radius: 10px;
    background-color: #F6F6F6;
    font-size: 14px;
  }
</style>