<template>
  <template v-for="(item, index) in dataList" :key="index">
    <Dropdown 
      v-if="item.list && item.list.length > 0" 
      placement="right-start">
      <DropdownItem
        :divided="item.divided"
        @click="onSelect(item)">
        <Text 
          style="width: 127px;" 
          :class="{'primary-color': item.projectIdStr==projectId}"
          ellipsis>{{ item.name }}</Text>
        <Icon type="ios-arrow-forward"></Icon>
      </DropdownItem>
      <template #list>
        <DropdownMenu>
          <XProjectMenu 
            :projectId="projectId"
            :onSelect="onSelect" 
            :dataList="item.list" />
        </DropdownMenu>
      </template>
    </Dropdown>
    <DropdownItem 
      v-else
      :divided="item.divided"
      @click="onSelect(item)">
      <Text 
        style="width: 127px;" 
        :class="{'primary-color': item.projectIdStr==projectId}"
        ellipsis>{{ item.name }}</Text>
    </DropdownItem>
  </template>
</template>

<script>
  export default {
    name: 'XProjectMenu',
    props: {
      onSelect: {
        type: Function,
        default: () => {}
      },
      dataList: {
        type: Array,
        default: () => []
      },
      projectId: { 
        default: ''
      }
    }
  }
</script>