<template>
  <XQuadrant v-if="selected=='quadrant'" />
  <XTaskMap v-if="selected=='map'" />
</template>

<script>
	import XQuadrant from '@/components/XQuadrant'
  import XTaskMap from '@/components/XTaskMap';

	export default {
		components: {
			XQuadrant,
			XTaskMap,
		},
		mounted() {
			this.selected = this.$route.params.item;
		},
		data() {
			return {
				selected: 'quadrant',
			}
		},
		watch: {
			$route() {
				this.selected = this.$route.params.item;
			}
		}
	}
</script>

<style lang="less" scoped>
</style>