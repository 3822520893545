<template>
  <draggable
    class="dragArea"
    v-bind="dragOptions"
    tag="ul"
    v-model="copyData.list"
    item-key="projectId"
    filter=".forbid"
    :move="onMove"
    @add="onAdd"
    @update="onUpdate"
  >
    <template #item="{element}">
      <li v-if="copyData.expand" style="background-color: #fff;" >
        <XTabNode :data="element"/>
        <XTabItem style="margin-left: 16px;" :data="element" />
      </li>
    </template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import XTabNode from "@/components/XTabNode"
import { projectUpdate } from '@/common/api'

let moveData = {};

export default {
  name: 'XTabItem',
  components: { draggable, XTabNode },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      copyData: this.data,
    };
  },
  computed: {
    dragOptions() {
      let grounpName = 'project';
      if (this.copyData.projectType == 1) {
        grounpName = this.copyData.projectIdStr;
      }
      return {
        animation: 200,
        group: grounpName,
        ghostClass: 'drag-ghost'
      }
    },
  },
  methods: {
    isOpen(element) {
      let open = this.$store.state.openFolder[element.parentProjectIdStr];
      return open != null ? open : true;
    },
    className(element) {
      return ('1' + this.$store.state.user.userId) == element.projectIdStr ? 'forbid' : '';
    },
    onMove(e) {
      moveData = e.draggedContext.element;
    },
    onEnd() {
      let index = moveData.projectType == 1 ? 0 : 1;
      let parent = this.$store.state.projectList[index];
      
      let project = this.searchParent(parent, moveData);
      projectUpdate(project).then(res => {
        if (res) {
          moveData.positionWeight = project.positionWeight;
        }
      });
    },
    onAdd() {
      moveData.parentProjectIdStr = this.data.projectIdStr;
      this.sortUpdate();
    },
    onUpdate() {
      this.sortUpdate();
    },
    sortUpdate() {
      let list = this.data.list || [];
      let index = list.indexOf(moveData);
      if (index == -1) return;
      if (index == 0) {
        if (list.length > 1) {
          moveData.positionWeight = list[1].positionWeight + 1;
        }
      } else if (index == list.length - 1) {
        moveData.positionWeight = list[this.data.list.length - 2].positionWeight - 1;
      } else {
        moveData.positionWeight = (list[index - 1].positionWeight + list[index + 1].positionWeight) / 2;
      }
      projectUpdate(moveData);
    },
    searchParent(parent, project) {
      let list = parent.list;
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        if (element.projectIdStr == project.projectIdStr) {
          let newProject = Object.assign({}, project);
          if (i == 0) {
            if (list.length > 1) {
              newProject.positionWeight = list[1].positionWeight + 1;
            }
          } else if (i == list.length - 1) {
            newProject.positionWeight = list[this.data.list.length - 2].positionWeight - 1;
          } else {
            newProject.positionWeight = (list[i - 1].positionWeight + list[i + 1].positionWeight) / 2;
          }
          newProject.parentProjectIdStr = parent.projectIdStr;
          newProject.parentProjectName = parent.name;
          return newProject;
        } else {
          let newProject = this.searchParent(element, project);
          if (newProject) return newProject;
        }
      }
      return null;
    },
  },
  watch: {
    data() {
      this.copyData = this.data;
    }
  }
}
</script>

<style lang="less" scoped>
  // .drag-ghost {
    // border: 1px solid #4772Fa;
  // }
  .dragArea {
    padding-top: 3px;
  }
</style>