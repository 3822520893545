<template>
  <Layout class="home" wrap>
    <XHomeHeader />
    <Content class="content">
      <router-view />
    </Content>
    <XDownloadModal />
  </Layout>
</template>

<script>
  import XHomeHeader from '@/components/XHomeHeader.vue';
  import XDownloadModal from '@/components/XDownloadModal.vue'
  import { userInfo } from "@/common/api"

  export default {
    components: {
      XHomeHeader,
      XDownloadModal
    },
    created() {
      this.$Spin.show();
      userInfo().then(res => {
        this.$Spin.hide();
        if (res) {
          this.$store.commit("updateInfo", res);
          if (this.$route.name !== 'home') {
            this.$router.replace("/manager/work");
          }
        } else {
          var userAgent = navigator.userAgent.toLowerCase();
          if (userAgent.indexOf('electron') > -1 && this.$route.path == '/') {
            this.$router.replace({name: 'login'});
          }
        }
      });
    }
  }
</script>

<style lang="less" scoped>
  .home {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .content {
      width: 100%; 
      overflow: scroll;
    }
  }
</style>