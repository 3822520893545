<template>
  <li @contextmenu.prevent.stop="onHeaderMore">
    <Space v-if="!data.tile" class="group-header" @click="onHeaderClick">
      <XIcon 
        icon-class="arrow_right" 
        size="15"
        :class="{'rotate-arrow': true, 'open': isOpen}" 
      />
      <XIcon v-if="data.key=='top'" icon-class="topping" size="16" />
      <Image v-if="data.icon" :src="data.icon" width="16px" />
      <span class="title" :style="{color: titleColor}">{{ data.name || '未置顶' }}</span>
      <span class="sub-title">{{ taskCount }}</span>
      <span v-if="data.key=='group'" class="more">
        <XIcon iconClass="add" @click.stop="onHeaderAdd" style="margin-right: 6px;" />
        <XIcon iconClass="more" @click.stop="onHeaderMore" />
      </span>
    </Space>
    <XTaskItem v-if="isOpen" :data="data" />
  </li>
</template>

<script>
import XTaskItem from '@/components/XTaskItem';
import { colorFromValue } from '@/common/util';
import { taskAdd } from '@/common/api';

export default {
  components: {
    XTaskItem,
  },
  props: {
    data: {
      required: true,
      type: Object,
      default: () => {}
    }
  },
  inject: ['onGroupMenuEvent'],
  data() {
    return {
      colorFromValue,

      isOpen: true,
    };
  }, 
  mounted() {
    
  },
  computed: {
    titleColor() {
      if (this.data.key == 'level') {
        return ['black', '#5877FA', '#FA9300', '#F12121'][this.data.level];
      } else if (this.data.key == 'top') {
        return '#3D87DA';
      } else if (this.data.color) {
        return colorFromValue(this.data.color);
      }
      return 'black';
    },
    taskCount() {
      let list = [].concat(this.data.list);
      let count = 0;
      while (list.length > 0) {
        let item = list.shift();
        if (item.list) {
          list = list.concat(item.list);
        }
        count++;
      }
      return count;
    }
  },
  methods: {

    onHeaderClick() {
      this.isOpen = !this.isOpen;
    },
    onHeaderAdd() {
      let positionWeight = 10;
      if (this.data.list.length > 0) {
        positionWeight = this.data.list[0].positionWeight + 10;
      }
      let task = {
        giveUp: false,
        completeTime: 0,
        title: '',
        level: 0,
        projectIdStr: this.data.projectIdStr,
        positionWeight,
        taskType: 0,
        groupId: this.data.groupIdStr
      };
      taskAdd(task).then(res => {
        if (res) {
          this.$store.commit('updateListFlag', { key: 'load' });
          this.$router.push('/manager/work/' + this.$route.params.project + '/' + res); 
        }
      });
    },
    onHeaderMore(event) {
      this.onGroupMenuEvent(event, this.data);
    }
  }
}
</script>

<style lang="less" scoped>
  .group-header {
    cursor: pointer;
    margin: 0 24px;
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid #f5f5f6;
    .rotate-arrow {
      transition: 0.3s all;
      transform: rotate(0deg);
      &.open {
        transform: rotate(90deg);
      }
    }
    .title {
      font-size: 12px;
      margin-right: 8px;
      color: black;
      font-weight: 700;
      &.top {
        color: #3D87DA;
      }
    }
    .sub-title {
      color: #b7b7b7;
      font-size: 12px;
    }
    &:hover {
      .more {
        display: inline;
      }
    }
    .more {
      display: none;
    }
  }

</style>