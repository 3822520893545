Date.prototype.format = function (fmt) {
  //author: meizz
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

/// 颜色转颜色值
export const colorToValue = (color) => {
  if (!color) return 0;
  // 去除所有空格
  color = color.replace(/\s*/g, "");
  let r = 255;
  let g = 255;
  let b = 255;
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  if (result) {
    r = parseInt(result[1], 16);
    g = parseInt(result[2], 16);
    b = parseInt(result[3], 16);
  } else {
    result = /^rgb\(?([\d]{1,3}),([\d]{1,3}),([\d]{1,3})\)$/i.exec(color);
    if (result) {
      r = parseInt(result[1]);
      g = parseInt(result[2]);
      b = parseInt(result[3]);
    }
  }
  return (0xff << 24) | (r << 16) | (g << 8) | b;
};

/// 颜色值转颜色
export const colorFromValue = (value) => {
  let r = ((value & 0xff0000) >> 16).toString(16).padStart(2, "0");
  let g = ((value & 0xff00) >> 8).toString(16).padStart(2, "0");
  let b = (value & 0xff).toString(16).padStart(2, "0");
  return "#" + r + g + b;
};

/// 是否是同一天
export const isSameDay = (startTime, endTime) => {
  const startTimeMs = new Date(startTime).setHours(0, 0, 0, 0);
  const endTimeMs = new Date(endTime).setHours(0, 0, 0, 0);
  return startTimeMs === endTimeMs;
};

/// 返回格式化的日期
export const formatTime = (oStartTime, oEndTime, judgeExpire = true) => {
  let nowTime = new Date().getTime();
  let lastTime = nowTime - 24 * 60 * 60 * 1000;
  let nextTime = nowTime - 24 * 60 * 60 * 1000;

  let startTime = "";
  let endTime = "";
  if (oStartTime > 0) {
    let minutes = new Date(oStartTime).format(" hh:mm");
    if (minutes == " 00:00") {
      minutes = "";
    }
    if (isSameDay(nowTime, oStartTime)) {
      startTime = "今天" + minutes;
    } else if (isSameDay(lastTime, oStartTime)) {
      startTime = "昨天" + minutes;
    } else if (isSameDay(nextTime, oStartTime)) {
      startTime = "明天" + minutes;
    } else {
      startTime = new Date(oStartTime).format("MM月dd日") + minutes;
    }
  }
  if (oEndTime > 0) {
    let minutes = new Date(oEndTime).format(" hh:mm");
    if (minutes == " 00:00") {
      minutes = "";
    }
    if (isSameDay(oStartTime, oEndTime)) {
      endTime = minutes;
    } else if (isSameDay(nowTime, oEndTime)) {
      endTime = "今天" + minutes;
    } else if (isSameDay(lastTime, oEndTime)) {
      endTime = "昨天" + minutes;
    } else if (isSameDay(nextTime, oEndTime)) {
      endTime = "明天" + minutes;
    } else {
      endTime = new Date(oEndTime).format("MM月dd日") + minutes;
    }
  }
  let outTip = "";
  if (judgeExpire && timeExpire(oStartTime, oEndTime)) {
    let maxTime = oEndTime > 0 ? oEndTime : oStartTime;
    if (maxTime < nowTime) {
      let d = (nowTime - maxTime) / 1000;
      let tip = "";
      if (d < 60) {
        tip = `${d}秒`;
      } else if (d < 60 * 60) {
        tip = `${Math.floor(d / 60)}分钟`;
      } else if (d < 24 * 60 * 60) {
        tip = `${Math.floor(d / 3600)}小时`;
      } else {
        tip = `${Math.floor(d / 24 / 3600)}天`;
      }
      outTip = " 过期" + tip;
    }
  }
  if (endTime.length > 0) {
    return startTime + "~" + endTime + outTip;
  }
  return startTime + outTip;
};

/// 判断时间是否过期 同一天不过期
export const timeExpire = (startTime, endTime) => {
  if (!startTime && !endTime) return false;
  let maxTime = endTime > 0 ? endTime : startTime;
  let nowTime = new Date().getTime();
  if (maxTime < nowTime && !isSameDay(maxTime, nowTime)) {
    return true;
  }
  return false;
};