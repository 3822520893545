<template>
  <svg :class="svgClass" aria-hidden="true" :style="{fontSize: size + 'px'}">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>

  const requireAll = requireContext => requireContext.keys().map(requireContext)
  const req = require.context('../assets', false, /\.svg$/)
  requireAll(req)
  const rich = require.context('../assets/rich', false, /\.svg$/)
  requireAll(rich)

  export default {
    name: "XIcon",
    props: {
      iconClass: {
        type: String,
        required: true,
      },
      className: {
        type: String,
        default: '',
      },
      size: {
        type: String,
        default: '16'
      }
    },
    computed: {
      iconName() {
        return `#icon-${this.iconClass}`
      },
      svgClass() {
        if (this.className) {
          return 'svg-icon ' + this.className
        } else {
          return 'svg-icon'
        }
      },
    }
  }
</script>

<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
  overflow: hidden;
  vertical-align: middle;
  cursor: pointer;
}
</style>