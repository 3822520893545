<template>
  <XCalendarComponent theme="normal" />
</template>

<script>
  import XCalendarComponent from '@/components/XCalendarComponent';

  export default {
    components: {
      XCalendarComponent
    },
  }
</script>