<template>
  <div v-if="$route.params.task" class="task-detail">
    <div class="detail-header">
      <div style="display: flex;">
        <Dropdown trigger="click" transfer>
          <div class="icon-bg">
            <XIcon :iconClass="showPriorityIcon" size="20" />
          </div>
          <template #list>
            <DropdownMenu>
              <DropdownItem v-for="value in priorityList" :key="value" @click="onLevelClick(value)">
                <XIcon :iconClass="value.icon" />&nbsp;
                <span :class="currentTask.level==value.index ? 'primary-color' : ''">{{ value.title }}</span>
              </DropdownItem>
            </DropdownMenu>
          </template>
        </Dropdown>
        <Poptip placement="bottom" v-model="showPoptip" class="fixed" @on-popper-show="poptipShow">
          <div class="project-btn hand">
            <span
              style="margin-left:3px;"
              @click.stop="showPoptip=true">
              {{ showProjectName }}-{{ showGroupName }}
              <XIcon iconClass="arrow_right" style="transform: rotate(90deg);" />
            </span>
          </div>
          <template #content>
            <div style="display: flex;">
              <Tree style="min-width: 150px;" :data="projectList" children-key="list" @on-select-change="onProjectTreeSelect"></Tree>
              <CellGroup style="border-left: 1px solid #F5F5F5; min-width: 150px;">
                <Cell
                  v-for="(group, index) in groupList"
                  :key="index"
                  style="color: #000"
                  :title="group.name"
                  @click="onGroupClick(group)" />
              </CellGroup>
            </div>
          </template>
        </Poptip>
        <div style="flex: 1;"></div>
        <!-- <div class="icon-bg">
          <XIcon iconClass="schedule" style="color: #979EA7;" @click="onAttachClick" />
        </div> -->
        <!-- <div class="icon-bg">
          <XIcon iconClass="location" style="color: #979EA7;" @click="(showMap=true)"/>
        </div> -->
        <!-- <Dropdown trigger="click" @on-visible-change="onTagChanged">
          <div class="icon-bg">
            <XIcon iconClass="tag" />
          </div>
          <template #list>
            <DropdownMenu style="width: 140px;">
              <template
                v-for="item in $store.state.tagList.list"
                :key="item.tagIdStr" >
                <Dropdown
                  v-if="item.list && item.list.length > 0"
                  placement="right-start">
                  <DropdownItem>
                    <Checkbox :modelValue="tagSelected(item)" @click.stop="onTagBoxClick(item)"></Checkbox>
                    <Text style="width: 75px;" ellipsis>{{ item.tagName }}</Text>
                    <Icon type="ios-arrow-forward"></Icon>
                  </DropdownItem>
                  <template #list>
                    <DropdownMenu>
                      <DropdownItem v-for="subItem in item.list" :key="subItem.tagIdStr">
                        <Checkbox :modelValue="tagSelected(subItem)" @click.stop="onTagBoxClick(subItem)"></Checkbox>
                        <Text style="width: 75px;" ellipsis>{{ subItem.tagName }}</Text>
                      </DropdownItem>
                    </DropdownMenu>
                  </template>
                </Dropdown>
                <DropdownItem v-else>
                  <Checkbox :modelValue="tagSelected(item)" @click.stop="onTagBoxClick(item)"></Checkbox>
                  <Text style="width: 75px;" ellipsis>{{ item.tagName }}</Text>
                </DropdownItem>
              </template>
            </DropdownMenu>
          </template>
        </Dropdown> -->
        <!-- <div class="icon-bg">
          <XIcon iconClass="font" @click="onFontClick" />
        </div> -->
        <!-- <Poptip :width="220" trigger="click" placement="bottom">
          <div class="icon-bg">
            <XIcon iconClass="line" />
          </div>
          <template #content>
            <div>
              <div class="space-between">
                <div
                  class="size-bg"
                  v-for="item, index in lineList"
                  :key="item"
                  :style="{marginLeft: index==0 ? '0px' : '8px'}"
                  @click="onLineClick(item.icon)" >
                  <XIcon :iconClass="item.icon" />
                  <br>
                  <span style="lineHeight: 20px">{{ item.title }}</span>
                </div>
              </div>
              <Divider style="margin: 10px 0;" />
              <span style="fontSize: 12px; color: #A6A6A6;">线条颜色</span>
              <Grid :col="6" :border="false" padding="5px 5px">
                <GridItem
                  style="text-align: center;"
                  v-for="color in lineColors"
                  :key="color"
                  @click="lineColor=color"
                >
                  <div :class="'color-item '+(color==lineColor?'select':'')" :style="{backgroundColor: color,}"></div>
                </GridItem>
              </Grid>
            </div>
          </template>
        </Poptip> -->
        <div class="icon-bg">
          <XIcon iconClass="more" @click.stop="onMoreClick"/>
        </div>
      </div>
      <div v-if="currentTask.taskType==2&&currentTask.endTime>0" style="display: flex; align-items: center; margin-top: 12px;">
        <img src="../assets/habit.png" width="" height="48">
        <span style="margin-left:12px;">习惯已结束</span>
      </div>
      <div style="display: flex; align-items: center; margin-top: 22px;">
        <XIcon
          v-if="currentTask.taskType==0"
          :iconClass="checkName"
          :style="checkStyle"
          @mousedown.stop="onCompleteClick"
          class="check"/>
        <XIcon v-if="currentTask.taskType==1" iconClass="file" />
        <!-- <Circle :percent="todayPercent(currentTask)" stroke-color="#ff5733" v-if="currentTask.taskType==2" :size="20"
        @click="updateClock(currentTask)">
          <Icon v-if="todayPercent(currentTask) == 100" type="ios-checkmark" size="20" style="color:#ff5733"></Icon>
          <Icon v-if="todayGiveUp(currentTask)" type="ios-close" size="20" style="color:#999"></Icon>
        </Circle> -->
        <div 
          v-if="currentTask.taskType==2"
          style="padding-top: 3px; cursor: pointer;"
          @click.stop="updateClock(currentTask)">
          <Icon 
            v-if="todayPercent(currentTask) == 100" 
            type="md-checkmark" 
            :size="20" 
            style="color:#3D87DA">
          </Icon>
          <Icon 
            v-else-if="todayGiveUp(currentTask)" 
            type="md-close" 
            size="20" 
            style="color:#999">
          </Icon>
          <Circle 
            v-else
            :percent="todayPercent(currentTask)" 
            stroke-color="#3D87DA" 
            :size="20">
          </Circle>
        </div>
        <Input
          class="title"
          v-model="currentTask.title"
          :border="false"
          placeholder="无标题"
          autosize
          type="textarea"
          @on-blur="onTitleBlur"
        />
      </div>
      <Space
        size="large"
        style="margin: 8px 0px; display: flex; flex-wrap: wrap; gap: 6px;"
        v-if="(currentTask.hasNotice || currentTask.hasRepeat || currentTask.fields || currentTask.startTime)"
      >
        <XIconButton
            v-if="currentTask.startTime"
            name="schedule"
            :title="showTime"
            :titleColor="timeTitleColor"
            :iconColor="timeColor"
            @click="onAttachClick" />

        <template v-if="currentTask.hasNotice && notices.length > 0">
          <XIconButton name="task_alert" :title="showNoticeTitle" @click="onAttachClick" />
        </template>
        <XIconButton v-if="currentTask.hasRepeat" name="task_cyclic" :title="repeat.msg" @click="onAttachClick" />
        <template
            v-if="currentTask.taskType == 2"
        >
          <XIconButton
              v-if="repeat.repeatMode != 15"
              name="task_cyclic"
              :title="`${currentTask.habitDTO.habitDayNum}${currentTask.habitDTO.habitUnit }/天`"
              titleColor="#999"
              @click="onAttachClick" />
        </template>
        <!-- <XIconButton v-if="currentTask.fields" name="task_accessory" title="附件" /> -->
      </Space>
      <Space direction="vertical" style="width: 100%;">
        <!-- <Text class="sub-title" ellipsis>{{ '这里说具体任务的描述，你想说点什么就说点什么' }}</Text> -->
        <Text
          v-if="currentTask.addressIdStr"
          class="sub-title"
          ellipsis>
          <XIcon size="16" iconClass="location" style='color: #3D87DA' />
          {{ showAddress }}
        </Text>
        <div v-if="currentTask.tagList && currentTask.tagList.length > 0">
          <Tag
            style="border-radius:13px; border:none;"
            v-for="tag in showTagList"
            :key="tag.tagIdStr"
            :color="showTagColor(tag.color) + '40'">
            <div style="display: flex; align-items: center;">
              <span :style="{ color: showTagColor(tag.color) }">{{ tag.tagName }}</span>
              <Icon style="line-height: 24px;" type="ios-close" @click="onTagClose(tag)" />
            </div>
          </Tag>
        </div>
        <div
          v-if="currentTask.parentTaskIdStr"
          style="color: #999999; font-size: 12px; display: flex; margin-top: 6px;"
          class="hand"
          @click="onParentTaskClick">
          <span style="margin-right: 4px;">父任务： {{ parentTask.title }}</span>
          <XIcon iconClass="arrow_double" />
        </div>
      </Space>
    </div>
    <div style="flex: 1;display: flex; overflow: auto; flex-direction: column;" v-if="currentTask.taskType !=2">
      <XRichText ref="richText" v-model="currentTask.content" />
      <div v-if="(subTasks.length > 0)" class="sub-task">
        <div v-for="task in subTasks" :key="task.taskIdStr" style="display: flex; align-items: center; padding: 5px;">
          <XIcon
            v-if="task.taskType==0"
            :iconClass="checkClass(task)"
            :style="taskCheckStyle(task)"
            @click.stop="onSubTaskCheck(task)"/>
          <XIcon v-if="task.taskType==1" iconClass="file" style="width: 20px;" />
          <Circle :percent="todayPercent(task)" stroke-color="#3D87DA" v-if="task.taskType==2" :size="20"
                  @click="updateClock(task)">
            <Icon v-if="todayPercent(task) == 100" type="ios-checkmark" :size="20" style="color:#ff5733"></Icon>
            <Icon v-if="todayGiveUp(task)" type="ios-close" size="20" style="color:#999"></Icon>
          </Circle>
          <Input
            :ref="'subInput_'+task.taskIdStr"
            class="title"
            v-model="task.title"
            :border="false"
            placeholder="无标题"
            autosize
            @on-blur="onSubTaskBlur(task)"
            @on-enter="onInputEnter(task)"
          />
          <Icon
            class="hand"
            type="ios-share-alt-outline"
            style="font-size: 20px; color: #3D87DA; margin-right: 5px;"
            @click="onSubTaskClick(task)"/>
          <Icon
            class="hand"
            type="ios-remove-circle-outline"
            style="font-size: 20px; color: #FA9300"
            @click="onSubTaskRemove(task)"/>
        </div>
      </div>
    </div>
    <div style="flex: 1;display: flex; overflow: auto; flex-direction: column;" v-if="currentTask.taskType == 2">
      <div style="min-height: 300px;display: flex;">
        <XRichText ref="richText" v-model="currentTask.content" />
      </div>
      <div style="display: flex;flex-direction: row;padding: 20px 10px;">
        <div style="flex: 1;background: #F5F9FF;padding: 10px;text-align: center;border-radius: 4px">
          <div style="font-size: 14px;color: rgb(51,51,51)">{{clockDateCount}}天</div>
          <div style="font-size: 14px;color: rgb(153,153,153);margin-top: 5px">总打卡</div>
        </div>
        <div style="width: 20px"></div>
        <div style="flex: 1;background: #F5F9FF;padding: 10px;text-align: center;border-radius: 4px">
          <div style="font-size: 14px;color: rgb(51,51,51)">{{clockAllCount}}次</div>
          <div style="font-size: 14px;color: rgb(153,153,153);margin-top: 5px">总打卡量</div>
        </div>
        <div style="width: 20px"></div>
        <div style="flex: 1;background: #F5F9FF;padding: 10px;text-align: center;border-radius: 4px">
          <div style="font-size: 14px;color: rgb(51,51,51)">{{clockConsecutiveCount}}天</div>
          <div style="font-size: 14px;color: rgb(153,153,153);margin-top: 5px">连续天数</div>
        </div>
      </div>
      <div style="background: #F5F9FF;padding: 20px 15px 0px;text-align: center;border-radius: 4px;margin: 0 10px 20px">
        <div style="font-size: 14px;color: rgb(51,51,51);margin-bottom: 20px;display: flex;justify-content:center;flex-direction: row;align-items: center">
          <XIcon icon-class="arrow_left" size="20"  @click="onPrevClick"/>
          <div style="width: 150px;text-align: center">{{ showTitle }}</div>
          <XIcon icon-class="arrow_right_black" size="20"  @click="onNextClick"/>
        </div>
        <FullCalendar ref="calendarM" :options="calendarMonthOptions" style="width: 100%">
          <template #dayCellContent="arg">
            <div class="circle-giveup" v-if="clockGiveUpDate.includes(dayjs(arg.date).format('YYYYMMDD'))">
              <Icon type="md-close" size="50" style="color:#aaa"></Icon>
            </div>
            <Circle :percent="clockDatesObject[dayjs(arg.date).format('YYYYMMDD')].percent" v-else-if="clockDates.includes(dayjs(arg.date).format('YYYYMMDD'))"
                    :size="50" :strokeWidth="5" :trailWidth="9">
              {{arg.dayNumberText}}
            </Circle>
            <Circle :percent="0" v-else-if="clockRepeatDate.includes(dayjs(arg.date).format('YYYYMMDD'))" :size="50" :strokeWidth="5" :trailWidth="9">
              {{arg.dayNumberText}}
            </Circle>
            <div class="circle-normal" v-else>{{arg.dayNumberText}}</div>
          </template>
        </FullCalendar>
        <div style="height: 0.5px;background: rgba(196, 222, 255, 1);width: 100%;margin-top: 20px"></div>
        <div style="display:flex;flex-direction: row;justify-content: space-evenly">
          <div style="padding: 20px;text-align: center;">
            <div style="font-size: 14px;color: rgb(51,51,51)">{{monthClockDateCount}}</div>
            <div style="font-size: 14px;color: rgb(153,153,153);margin-top: 5px">月打卡</div>
          </div>
          <div style="padding: 20px;text-align: center;">
            <div style="font-size: 14px;color: rgb(51,51,51)">{{monthClockAllCount}}</div>
            <div style="font-size: 14px;color: rgb(153,153,153);margin-top: 5px">月打卡量</div>
          </div>
          <div style="padding: 20px;text-align: center;">
            <div style="font-size: 14px;color: rgb(51,51,51)">{{ monthClockPercent }}</div>
            <div style="font-size: 14px;color: rgb(153,153,153);margin-top: 5px">月完成率</div>
          </div>
        </div>
      </div>
      <div style="background: #F5F9FF;padding: 20px 24px;border-radius: 4px;margin: 0 10px 20px" class="calendar-year">
        <div style="margin-bottom:20px;font-size: 14px;color: rgb(51,51,51);display: flex;flex-direction: row;align-items:center;justify-content: space-between">
          2023年统计
          <div style="font-size: 12px;color: rgb(153,153,153);">打卡{{clockDateCount}}天，共{{clockAllCount}}次</div>
        </div>
        <FullCalendar ref="calendarY" :options="calendarYearOptions" >
          <template #dayCellContent="arg">
            <div class="dot-giveup" v-if="clockGiveUpDate.includes(dayjs(arg.date).format('YYYYMMDD'))"></div>
            <div class="dot-active" :style="`opacity: ${clockDatesObject[dayjs(arg.date).format('YYYYMMDD')].percent*1.0/100}`" v-else-if="clockDates.includes(dayjs(arg.date).format('YYYYMMDD'))"></div>
            <div class="dot" v-else></div>
          </template>
        </FullCalendar>
      </div>
      <div style="background: #F5F9FF;padding: 20px 24px;border-radius: 4px;margin: 0 10px 20px">
        <div style="font-size: 14px;color: rgb(51,51,51)">习惯日志</div>
        <div v-for="item in clockLogList" :key="item.clockedHistoryId" style="margin-top: 10px">
          <Space>
            <div style="font-size: 14px;color: rgb(153,153,153);width: 80px">{{item.timeStr}}</div>
            <Image width="30px" height="30px" fit="cover" v-if="item.clockedMood" :src="icons[item.clockedMood][0]"></Image>
            <div style="flex:1;font-size: 14px;color: rgb(51,51,51)">{{item.clockedLog}}</div>
          </Space>
        </div>
      </div>
    </div>
    <XAttachModal
      :show="showModal"
      :data="{...attach,attachType:currentTask.taskType}"
      @onModalChanged="onModalChanged"
      @onModalOk="onModalOk"/>
    <Modal :mask="false"
      :width="620"
      v-model="showMap"
      title="选择地图"
      @on-ok="onMapModalOk">
      <Text style="margin-bottom: 6px;" ellipsis :ellipsis-config="{ tooltip: true }">{{ location.address || '点击地图选择位置' }}</Text>
      <XMap v-if="showMap" @onSelected="onMapSelected" />
    </Modal>
  </div>
  <div v-else style="background-color: white; height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <template v-if="$store.state.taskList.length > 0">
      <XIcon iconClass="nodetail" style="font-size: 116px;" />
      <p style="margin-top: 16px; color: #A6A6A6; font-size: 12px;">点击任务标题查看详情</p>
    </template>
  </div>
</template>

<script>

import { colorFromValue, formatTime, timeExpire } from '@/common/util';
import XIconButton from './XIconButton';
import XRichText from './XRichText';
import XAttachModal from "@/components/XAttachModal";
import XMap from "@/components/XMap";
import {
  noticeDetail,
  taskUpdate,
  repeatDetail,
  addressAdd,
  addressList,
  groupList,
  noticeAdd,
  noticeDelete,
  repeatAdd,
  repeatDelete, habitUpdate, clockedUpdate, clockedAdd,
} from '@/common/api';
import {Image, Space} from "view-ui-plus";
import multiMonthPlugin from "@fullcalendar/multimonth";
import FullCalendar from "@fullcalendar/vue3";
import {dayjs} from "element-plus";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timelinePlugin from "@fullcalendar/timeline";
import calendar from "@/common/calendar";
import cryIcon from '@/assets/cry.png'
import cryActiveIcon from '@/assets/cry_active.png'
import nervousIcon from '@/assets/nervous.png'
import nervousActiveIcon from '@/assets/nervous_active.png'
import normalIcon from '@/assets/normal.png'
import normalActiveIcon from '@/assets/normal_active.png'
import goodIcon from '@/assets/good.png'
import goodActiveIcon from '@/assets/good_active.png'
import happyIcon from '@/assets/happy.png'
import happyActiveIcon from '@/assets/happy_active.png'
import { HolidayUtil } from 'lunar-javascript'


const priorityList = [{
  title: '高优先级',
  icon: 'flag_high',
  index: 3
}, {
  title: '中优先级',
  icon: 'flag_middle',
  index: 2
}, {
  title: '低优先级',
  icon: 'flag_low',
  index: 1
}, {
  title: '无优先级',
  icon: 'flag_normal',
  index: 0
}];

const lineList = [{
  title: '实线',
  icon: 'line_solid'
}, {
  title: '虚线',
  icon: 'line_dotted'
}, {
  title: '点线',
  icon: 'line_point'
}];

const lineColors = [
  '#000000', '#383838', '#808080', '#A6A6A6', '#CCCCCC', '#E6E6E6',
  '#FF5733', '#FF7E33', '#FFA733', '#FFC933', '#FFE033', '#F9ECC7',
  '#6E8A31', '#6CB85D', '#43CF7C', '#71AFBD', '#93CDF9', '#CCE4FB',
];

export default {
  components: {
    Image,
    Space,
    XIconButton,
    XRichText,
    XAttachModal,
    XMap,
    FullCalendar
  },
  data() {
    return {
      icons :{
        'cry':[cryIcon,cryActiveIcon],
        'nervous':[nervousIcon,nervousActiveIcon],
        'normal':[normalIcon,normalActiveIcon],
        'good':[goodIcon,goodActiveIcon],
        'happy':[happyIcon,happyActiveIcon],
      },
      dayjs,
      showTitle: dayjs().format("YYYY年M月"),
      calendar: {},
      calendarApi: {},
      calendarYearOptions: {
        firstDay: localStorage.getItem("weekBegin") || 0,
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        headerToolbar: false,
        multiMonthMinWidth: 100,
        multiMonthMaxColumns:6,
        height: 'auto',
        contentHeight: 100,
        plugins: [multiMonthPlugin],
        initialView: 'multiMonthYear',
        events: [],
        fixedWeekCount:false,
        locale: "zh-cn",
        // dayCellContent: this.dayContentHook,
      },
      calendarMonthOptions: {
        firstDay: localStorage.getItem("weekBegin") || 0,
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        headerToolbar: false,
        height: 'auto',
        plugins: [dayGridPlugin,interactionPlugin,timelinePlugin],
        initialView: 'dayGridMonth',
        events: [],
        fixedWeekCount:false,
        locale: "zh-cn",
        editable: true,
        showNonCurrentDates: false,
        dateClick: this.dateClick,
      },
      showPoptip: false,
      groupList: [],

      showModal: false,
      attach: {},

      notices: [],
      repeat: {},

      showMap: false,
      location: {},
      lineList,
      lineColors,
      lineColor: '#000000',
      dataList: [{"align":0,"check":false,"indent":0,"number":0,"position":0,"quote":false,"spanList":[{"background":false,"bold":false,"color":-16711936,"content":"1234","fontSize":77,"italic":false,"strikethrough":true,"underlined":false},{"background":true,"bold":true,"color":-65536,"content":"拉呢数据","fontSize":39,"italic":false,"strikethrough":true,"underlined":true}],"spanText":"1234拉呢数据","textType":0,"type":1}],
      // dataList: [{"align":0,"check":false,"indent":0,"number":0,"position":0,"quote":false,"spanList":[{"background":false,"bold":false,"color":-16711936,"content":"1234","fontSize":77,"italic":false,"strikethrough":true,"underlined":false},{"background":true,"bold":true,"color":-65536,"content":"拉呢数据","fontSize":39,"italic":false,"strikethrough":true,"underlined":true}],"spanText":"我是文本","textType":0,"type":1},{"align":0,"check":false,"indent":0,"number":0,"position":0,"quote":false,"spanList":[],"spanText":"我是清单","textType":2,"type":1},{"align":0,"check":false,"indent":0,"number":1,"position":0,"quote":false,"spanList":[],"spanText":"我是序号","textType":3,"type":1},{"align":0,"check":false,"indent":0,"number":2,"position":0,"quote":false,"spanList":[],"spanText":"我是列表","textType":4,"type":1},{"align":0,"check":false,"indent":0,"number":0,"position":0,"quote":true,"spanList":[],"spanText":"我是引用加列表","textType":4,"type":1},{"align":1,"check":false,"indent":0,"number":0,"position":0,"quote":false,"spanList":[],"spanText":"我居中了","textType":0,"type":1},{"align":0,"check":false,"indent":3,"number":0,"position":0,"quote":false,"spanList":[],"spanText":"我缩进了","textType":0,"type":1},{"dividerColor":-3710700,"dividerType":2,"type":20},{"imageInfoList":[{"localPath":"wx_camera_1659762361098~1.jpg","scale":0.51641667,"transferX":476.80017,"transferY":97.28783},{"localPath":"Screenshot_2022-08-05-18-07-06-72_9a62a2ceffe18bd45e4480e385a71339~6.jpg","scale":0.1590909,"transferX":173.50003,"transferY":39.189087}],"type":11},{"localPath":"激活码08_01.txt","remotePath":"4FF5922CB2736996CBF97F9E26227037/1659776020570激活码08_01.txt","type":14},{"align":0,"check":false,"indent":0,"number":0,"position":0,"quote":false,"spanList":[],"spanText":"","textType":0,"type":1}]
      priorityList: priorityList,
      subTasks: [],
      checked: false,
    }
  },
  mounted() {
    let projectId = this.$route.params.project;
    if (projectId && projectId.length > 0) {
      groupList(projectId).then(res => {
        if (res) {
          this.groupList = res.sort((a, b) => b.positionWeight - a.positionWeight);
        }
      });
    }
  },
  computed: {
    parentTask() {
      let task = this.taskList.find(item => item.taskIdStr == this.currentTask.parentTaskId);
      return task ?? {};
    },
    showProjectName() {
      let project = this.$store.state.baseProjectList.find(item => item.projectIdStr == this.currentTask.projectIdStr);
      if (project) {
        return project.name;
      }
      return '';
    },
    showGroupName() {
      if (this.currentTask.groupIdStr) {
        let group = this.groupList.find(item => item.groupIdStr == this.currentTask.groupIdStr);
        if (group) {
          return group.name;
        }
      }
      return '未分组';
    },
    taskList() {
      return this.$store.state.taskList;
    },
    checkStyle() {
      const colors = {
        0: 'black',
        3: '#F12121',
        2: '#FA9300',
        1: '#5877FA'
      };
      let key = this.currentTask.level || 0;
      let color = colors[key];
      return {
        color,
        fontSize: '20px',
      };
    },
    projectList() {
      return this.$store.getters.normalProjectList;
    },
    currentProject() {
      return this.$store.state.currentProject;
    },
    currentTask() {
      console.log("task",this.$store.state.currentTask)
      return this.$store.state.currentTask;
    },
    checkName() {
      if (this.currentTask.giveUp) {
        return 'check_invalid';
      } else if (this.currentTask.completeTime > 0) {
        return 'check_complete';
      }
      return 'check';
    },
    showAddress() {
      for (let i = 0; i < this.$store.state.addressList.length; i++) {
        const element = this.$store.state.addressList[i];
        if (element.addressIdStr == this.currentTask.addressIdStr) {
          return element.poiAddress;
        }
      }
      return '';
    },
    showTagList() {
      let list = [];
      this.currentTask.tagList.forEach(e1 => {
        this.$store.state.baseTagList.forEach(e2 => {
          if (e1 == e2.tagIdStr) {
            list.push(e2);
          }
        });
      });
      return list;
    },
    timeTitleColor() {
      return timeExpire(this.currentTask.startTime, this.currentTask.endTime) ? '#999' : '#3D87DA';
    },
    timeColor() {
      return timeExpire(this.currentTask.startTime, this.currentTask.endTime) ? '#979EA7' : '#3D87DA';
    },
    timeExpired() {
      let nowTime = new Date().getTime();
      return this.currentTask.endTime > 0 && this.currentTask.endTime < nowTime;
    },
    showTime() {
      return formatTime(this.currentTask.startTime, this.currentTask.endTime, this.currentTask.completeTime == 0);
    },
    showPriorityIcon() {
      let iconName = '';
      this.priorityList.forEach(element => {
        if (element.index == this.currentTask.level) {
          iconName = element.icon;
        }
      });
      return iconName;
    },
    showNoticeTitle() {
      if (this.notices && this.notices.length > 0) {
        return this.notices.map(item => item.msg).join(',');
      }
      return '';
    },
    clockRepeatDate(){
      let array = [];
      let index = 1;
      let weekIndex = 0;
      let start = dayjs(this.currentTask.startTime);
      let startTotalMonth = start.year() *12 + start.month();
      const end = dayjs(this.taskEnd,'YYYYMMDD');
      const abhs = [1,2,3,5,8,16];
      while (start <= end){
        //判断日期是否符合条件，符合条件加入array数据
        if (this.repeat.self){//自定义
          switch (this.repeat.startMode){
            case 2://按时间
              if (this.repeat.repeatMode == 1){//按天
                if (index % this.repeat.interval == 1){
                  const holiday = HolidayUtil.getHoliday(start.format('YYYYMMDD'));
                  if (this.repeat.jumpHoliday && holiday){
                    if (holiday.isWork()){
                      if (this.repeat.jumpWeekend){
                        if ( start.day() >= 1 && start.day() <= 5){
                          array.push(start.format('YYYYMMDD'))
                        }
                      }else{
                        array.push(start.format('YYYYMMDD'))
                      }
                    }
                  }else{
                    if (this.repeat.jumpWeekend){
                      if (start.day() >= 1 && start.day() <= 5){
                        array.push(start.format('YYYYMMDD'))
                      }
                    }else{
                      array.push(start.format('YYYYMMDD'))
                    }
                  }
                }
              }else if (this.repeat.repeatMode == 2){//按周
                if (weekIndex % this.repeat.interval == 0 && this.repeat.weekdays?.includes(start.day()+1)){
                  const holiday = HolidayUtil.getHoliday(start.format('YYYYMMDD'));
                  if (this.repeat.jumpHoliday && holiday){
                    if (holiday.isWork()){
                      array.push(start.format('YYYYMMDD'))
                    }
                  }else{
                    array.push(start.format('YYYYMMDD'))
                  }
                }
              }else if (this.repeat.repeatMode == 3){//按月
                console.log("zzzz",start.year() *12 + start.month(),startTotalMonth)
                const monthEnd = start.endOf('month').format("YYYYMMDD")
                if (
                    ((start.year() *12 + start.month() - startTotalMonth) %  this.repeat.interval == 0)
                    && (this.repeat.monthDays?.includes(start.date())
                || (this.repeat.monthLast && start.format("YYYYMMDD")==monthEnd)
                )){
                  const holiday = HolidayUtil.getHoliday(start.format('YYYYMMDD'));
                  if (this.repeat.jumpHoliday && holiday){
                    if (holiday.isWork()){
                      if (this.repeat.jumpWeekend){
                        if ( start.day() >= 1 && start.day() <= 5){
                          array.push(start.format('YYYYMMDD'))
                        }
                      }else{
                        array.push(start.format('YYYYMMDD'))
                      }
                    }
                  }else{
                    if (this.repeat.jumpWeekend){
                      if (start.day() >= 1 && start.day() <= 5){
                        array.push(start.format('YYYYMMDD'))
                      }
                    }else{
                      array.push(start.format('YYYYMMDD'))
                    }
                  }
                }
              }else{//TODO 其他暂时返回无限期
                array.push(start.format('YYYYMMDD'))
              }
              break
            case 4://按天数  //TODO 打卡几天后 本周本月本年就没有习惯任务量了，暂时返回无限期
              array.push(start.format('YYYYMMDD'))
              break
            case 3://固定日
              //eslint-disable-next-line no-case-declarations
              const holiday = HolidayUtil.getHoliday(start.format('YYYYMMDD'));
              if (this.repeat.fixedDays?.includes(start.valueOf())){
                if (this.repeat.jumpHoliday && holiday){
                  if (holiday.isWork()){
                    array.push(start.format('YYYYMMDD'))
                  }
                }else{
                  array.push(start.format('YYYYMMDD'))
                }
              }
              break
          }
        }else{//固定模式
          switch (this.repeat.repeatMode){
            case 1://每日
              //eslint-disable-next-line no-case-declarations
              array.push(start.format('YYYYMMDD'))
              break
            case 2://周
                console.log("周",start.day())
              if (this.repeat.weekdays?.includes(start.day()+1)){
                array.push(start.format('YYYYMMDD'))
              }
              break
            case 3://月
              if (this.repeat.yearDay == start.date()-1){
                array.push(start.format('YYYYMMDD'))
              }
              break
            case 4://年
              if (this.repeat.yearDay == start.date() && this.repeat.yearMonth == start.month()+1){
                array.push(start.format('YYYYMMDD'))
              }
              break
            case 5://工作日
              if (start.day() >= 1 && start.day() <= 5 ){
                array.push(start.format('YYYYMMDD'))
              }
              break
            case 6://法定工作日
              //eslint-disable-next-line no-case-declarations
              const holiday = HolidayUtil.getHoliday(start.format('YYYYMMDD'));
              if (start.day() >= 1 && start.day() <= 5 ){
                if (holiday && !holiday.isWork()){
                 break;
                }
                array.push(start.format('YYYYMMDD'))
              }else{
                if (holiday && holiday.isWork()){
                  array.push(start.format('YYYYMMDD'))
                }
              }
              break
            case 7://法定节假日
              //eslint-disable-next-line no-case-declarations
              const h = HolidayUtil.getHoliday(start.format('YYYYMMDD'));
              if (start.day() < 1  || start.day() > 5 ){
                if (h && h.isWork()){
                  break;
                }
                array.push(start.format('YYYYMMDD'))
              }else{
                if (h && !h.isWork()){
                  array.push(start.format('YYYYMMDD'))
                }
              }
              break
            case 8://艾宾浩斯 [1,2,4,7,15,30,45,60,75,90...]
                if (abhs.includes(index) || (index > abhs[abhs.length-1] && index % 15 == abhs[abhs.length-1] % 15)){
                  array.push(start.format('YYYYMMDD'))
                }
              break
            default:
              break
          }
        }
        if (start.day()==0){
          weekIndex++;
        }
        start = start.add(1,'day')
        index++;
      }
      return array;
      // dayjs(arg.date).format('YYYYMMDD')>= taskStart && dayjs(arg.date).format('YYYYMMDD')<= taskEnd
    },
    clockGiveUpDate(){
      let array = [];
      if (this.currentTask.taskType == 2 && this.currentTask.clockedHistoryDTOList){
        array = this.currentTask.clockedHistoryDTOList.filter(item=>{
          let records = []
          try {
            records = JSON.parse(item.clockedDetail);
          }catch (e){
            console.log(e)
          }
          if (records.length){
            const content = records[records.length-1].content;
            if (content =='放弃了打卡'){
              return true;
            }
          }
          return false;
        }).map(item=>{
          return dayjs(item.time).format("YYYYMMDD");
        })
      }
      console.log("giveup",array)
      return array;
    },
    clockLogList(){
      let array = [];
      if (this.currentTask.taskType == 2 && this.currentTask.clockedHistoryDTOList){
        array = this.currentTask.clockedHistoryDTOList.filter(item=>{
          return (item.clockedLog && item.clockedLog.trim())||item.clockedMood;
        }).map(item=>{
          item.timeStr = dayjs(item.time).format("MM月DD日")
          return item;
        })
      }
      array.sort((v1,v2)=>{
        return v2.time - v1.time;
      })
      return array;
    },
    clockDateCount(){
      let array = [];
      if (this.currentTask.taskType == 2 && this.currentTask.clockedHistoryDTOList){
        array = this.currentTask.clockedHistoryDTOList.filter(item=>{
          return item.complete;
        })
      }
      return array.length;
    },
    clockDates(){
      let array = [];
      if (this.currentTask.taskType == 2 && this.currentTask.clockedHistoryDTOList){
        array = this.currentTask.clockedHistoryDTOList.filter(item=>{
          return item.completeNum > 0;
        }).map(item=>{
          return dayjs(item.time).format("YYYYMMDD");
        })
      }
      return array;
    },
    clockDatesObject(){
      let obj = {};
      if (this.currentTask.taskType == 2 && this.currentTask.clockedHistoryDTOList){
        obj = this.currentTask.clockedHistoryDTOList.filter(item=>{
          item.percent = item.completeNum *100 / item.totalNum;
          if (item.percent > 100){
            item.percent = 100;
          }
          return item.completeNum > 0;
        }).reduce((pre,item)=>{
          pre[dayjs(item.time).format("YYYYMMDD")] = item;
          return pre;
        },{})
      }
      return obj;
    },
    clockAllCount(){
      let count = 0;
      if (this.currentTask.taskType == 2 && this.currentTask.clockedHistoryDTOList){
        count = this.currentTask.clockedHistoryDTOList.reduce((pre,item)=>{
          return pre + item.completeNum;
        },0)
      }
      return count;
    },
    clockConsecutiveCount(){
      let count = 0;
      if (this.currentTask.taskType == 2 && this.currentTask.clockedHistoryDTOList){
        let filterArray = this.currentTask.clockedHistoryDTOList.filter(item=>{
          item.timeStr = dayjs(item.time).format("YYYYMMDD");
          // return item.complete;
          return item.completeNum > 0;
        });
        filterArray = filterArray.sort((v1, v2)=>{
          return v2.time-v1.time
        });
        console.log("filterArray",filterArray)
        //如果最大(第一个)的打卡记录是今天或者昨天
        const todayStr = dayjs().format("YYYYMMDD");
        const yestodayStr = dayjs().subtract(1,'day').format("YYYYMMDD");
        let before = ''
        for (let i = 0; i < filterArray.length; i++) {
          const dayStr = dayjs(filterArray[i].time).format("YYYYMMDD");
          if (i == 0){
              if (dayStr!= todayStr && dayStr != yestodayStr){
                break
              }else{
                before = dayStr;
                count = 1;
              }
          }else{
            if (dayStr == before - 1){
              before = dayStr;
              count ++;
            }else{
              break
            }
          }
        }
      }
      return count;
    },
    taskStart(){
      const startTime = dayjs(this.currentTask.startTime).format("YYYYMMDD");
      return startTime;
    },
    taskEnd(){
      if (this.repeat.endMode == 0){
        return dayjs().format("YYYYMMDD")
      }else if (this.repeat.endMode == 1){//TODO 打卡几天后结束 应该改为打卡几次结束，暂时返回无限期
        // let format = dayjs(this.currentTask.startTime).add(this.repeat.repeatEndTimes,'day').format("YYYYMMDD");
        // if (dayjs().format("YYYYMMDD") <= format || this.repeat.repeatEndTimes==0){
        //   format = dayjs().format("YYYYMMDD");
        // }
        // return format;
        return dayjs().format("YYYYMMDD")
      }else if (this.repeat.endMode == 2){
        let format = dayjs(this.repeat.repeatEndDate).format("YYYYMMDD");
        if (dayjs().format("YYYYMMDD") <= format || this.repeat.repeatEndTimes==0){
          format = dayjs().format("YYYYMMDD");
        }
        return format;
      }
      return dayjs().format("YYYYMMDD")
    },
    monthClockDateCount(){
      const month = dayjs(this.showTitle,'YYYY年M月').month();
      let array = [];
      if (this.currentTask.taskType == 2 && this.currentTask.clockedHistoryDTOList){
        array = this.currentTask.clockedHistoryDTOList.filter(item=>{
          return item.complete && dayjs(item.time).format("YYYYMMDD") >= this.taskStart
              && dayjs(item.time).format("YYYYMMDD") <= this.taskEnd && dayjs(item.time).month() == month;
        })
      }
      if (this.monthClockDateAllCount == 0){
        return "0天"
      }else{
        return array.length + "/"+this.monthClockDateAllCount+"天"
      }
    },
    monthClockDateAllCount(){
      const month = dayjs(this.showTitle,'YYYY年M月').month();
      const year = dayjs(this.showTitle,'YYYY年M月').year();
      let count = calendar.solarDays(year,month+1)
      const originCount = count;

      const sMonth = dayjs(this.currentTask.startTime).month();
      if (sMonth > month){
        return 0
      }else if (sMonth == month){
        count = count -  dayjs(this.currentTask.startTime).date() + 1;
      }
      if (this.repeat.repeatMode == 2){
        const eMonth = dayjs(this.currentTask.startTime).add(this.repeat.repeatEndTimes,'day').month();
        if (eMonth < month) {
          return 0
        } else if (eMonth == month) {
          count = count - originCount - dayjs(this.currentTask.startTime).add(this.repeat.repeatEndTimes,'day').date();
        }
      }else if (this.repeat.repeatMode == 3){
        const eMonth = dayjs(this.repeat.repeatEndDate).month();
        if (eMonth < month) {
          return 0
        } else if (eMonth == month) {
          count = count - originCount - dayjs(this.repeat.repeatEndDate).date();
        }
      }
      return count
    },
    monthClockAllCount(){
      const month = dayjs(this.showTitle,'YYYY年M月').month();
      let array = [];
      let completeNum = 0;
      let totalNum = 0;
      if (this.currentTask.taskType == 2 && this.currentTask.clockedHistoryDTOList){
        array = this.currentTask.clockedHistoryDTOList.filter(item=>{
          return dayjs(item.time).format("YYYYMMDD") >= this.taskStart
              && dayjs(item.time).format("YYYYMMDD") <= this.taskEnd && dayjs(item.time).month() == month;
        });
        completeNum = array.reduce((pre,item)=>{
          return pre + item.completeNum;
        },0)
        const pc = this.currentTask.habitDTO.habitDayNum;//每天打卡几次 从配置里取
        totalNum = array.reduce((pre,item)=>{
          return pre -pc+ item.totalNum;
        },this.monthClockDateAllCount * pc)
      }
      if (totalNum == 0){
        return "0次"
      }else{
        return `${completeNum}/${totalNum}次`
      }
    },
    monthClockPercent(){
      let p  = "--"
      if (this.monthClockDateAllCount!=0){
        const month = dayjs(this.showTitle,'YYYY年M月').month();
        let array = [];
        if (this.currentTask.taskType == 2 && this.currentTask.clockedHistoryDTOList){
          array = this.currentTask.clockedHistoryDTOList.filter(item=>{
            return item.complete && dayjs(item.time).format("YYYYMMDD") >= this.taskStart
                && dayjs(item.time).format("YYYYMMDD") <= this.taskEnd && dayjs(item.time).month() == month;
          })
        }
        return parseInt(array.length *100/this.monthClockDateAllCount) +"%";
      }
      return p;
    },
  },
  methods: {
    poptipShow() {
      if (this.currentTask.projectIdStr) {
        groupList(this.currentTask.projectIdStr).then(res => {
          if (res) {
            this.groupList = res.sort((a, b) => b.positionWeight - a.positionWeight);
          }
        });
      }
    },
    onSubTaskCheck(task) {
      if (task.giveUp || task.completeTime > 0) {
        task.completeTime = 0;
        task.giveUp = false;
      } else {
        task.completeTime = Date.now();
        if (!task.originTime) {
          task.originTime = task.startTime;
        }
      }
      this.$store.commit('updateListFlag', {
        key: 'update',
        value: task
      });
    },
    onSubTaskBlur(task) {
      taskUpdate(task);
    },
    onSubTaskClick(task) {
      this.$router.push('/manager/work/' + this.currentTask.projectIdStr + '/' + task.taskIdStr).then(() => {
        this.$store.commit('taskChanged', task);
      });
    },
    onSubTaskRemove(task) {
      this.$store.commit('updateListFlag', {
        key: 'delete',
        value: task
      });
    },
    onInputEnter(task) {
      let key = 'subInput_' + task.taskIdStr;
      let obj = this.$refs[key];
      if (Array.isArray(obj) && obj.length > 0) {
        obj[0].blur();
      } else {
        obj.blur();
      }
      this.$store.commit('updateListFlag', { key: 'subTask' });
    },
    checkClass(task) {
      if (task.giveUp) {
        return 'check_invalid';
      } else if (task.completeTime > 0) {
        return 'check_complete';
      }
      return 'check';
    },
    taskCheckStyle(task) {
      const colors = {
        0: 'black',
        3: '#F12121',
        2: '#FA9300',
        1: '#5877FA'
      };
      let key = task.level || 0;
      let color = colors[key];
      return {
        color,
        width: '20px'
      };
    },
    onTagClose(tag) {
      let index = this.currentTask.tagList.indexOf(tag.tagIdStr);
      this.currentTask.tagList.splice(index, 1);
      taskUpdate(this.currentTask);
    },
    searchSubTask() {
      let list = [];
      this.taskList.forEach(element => {
        if (element.parentTaskIdStr == this.$route.params.task) {
          list.push(element);
        }
      });
      this.subTasks = list.sort((a, b) => b.positionWeight - a.positionWeight);
      setTimeout(() => {
        if (this.$store.state.focusId) {
          let key = 'subInput_' + this.$store.state.focusId;
          if (this.$refs[key]) {
            this.$refs[key][0].focus();
            this.$store.commit('updateFocusId', null);
          }
        }
      }, 100);
    },
    onProjectTreeSelect(list) {
      if (list && list.length > 0) {
        this.showPoptip = false;
        let project = list[0];
        if (this.currentTask.projectIdStr != project.projectIdStr) {
          this.currentTask.projectIdStr = project.projectIdStr;
          this.currentTask.parentTaskId = null;
          this.currentTask.parentTaskIdStr = null;
          this.currentTask.groupIdStr = null;
          taskUpdate(this.currentTask).then((res) => {
            if (res) {
              this.$store.commit('updateListFlag', { key: 'load' });
              this.$Message.success(`${this.currentTask.title} 已移动到 ${project.name}`);
            }
          });
        }
      }
    },
    onGroupClick(group) {
      if (this.currentTask.groupIdStr != group.groupIdStr) {
        this.showPoptip = false;
        this.currentTask.groupIdStr = group.groupIdStr;
        taskUpdate(this.currentTask).then((res) => {
          if (res) {
            this.$store.commit('updateListFlag', { key: 'load' });
            this.$Message.success(`${this.currentTask.title} 已移动到 ${group.name}`);
          }
        });
      }
    },
    onParentTaskClick() {
      this.$router.push('/manager/work/' + this.$route.params.project + '/' + this.currentTask.parentTaskId).then(() => {
        this.$store.commit('taskChanged', this.parentTask);
      });
    },
    onLevelClick(priority) {
      this.currentTask.level = priority.index;
      taskUpdate(this.currentTask);
    },
    onCompleteClick() {
      let task = this.currentTask;
      if (task.giveUp || task.completeTime > 0) {
        task.completeTime = 0;
        task.giveUp = false;
      } else {
        task.completeTime = Date.now();
        if (!task.originTime) {
          task.originTime = task.startTime;
        }
      }
      this.$store.commit('updateListFlag', { key: 'update' });
    },
    showTagColor(value) {
      return value ? colorFromValue(value) : '#3D87DA';
    },
    loadNotices() {
      if (this.currentTask.taskIdStr && this.currentTask.hasNotice) {
        noticeDetail(this.currentTask.taskIdStr).then(res => {
          if (res) {
            this.notices = res;
          }
        });
      } else {
        this.notices = [];
      }
      if (this.currentTask.taskIdStr && this.currentTask.hasRepeat) {
        repeatDetail(this.currentTask.taskIdStr).then(res => {
          if (res) {
            this.repeat = res;
          }
        });
      } else {
        this.repeat = {};
      }
    },
    onAttachClick() {
      this.attach = {
        startTime: this.currentTask.startTime,
        endTime: this.currentTask.endTime,
        // notices: [...(this.notices || [])],
        notices: [...(this.notices)],
        repeat: this.repeat,
        habit: this.currentTask.habitDTO,
        noticeInfo: this.currentTask.noticeInfo,
      };
      this.showModal = true;
    },
    onModalChanged(value) {
      this.showModal = value;
    },
    async onModalOk(attach) {
      this.currentTask.startTime = attach.startTime;
      this.currentTask.originTime = attach.startTime;
      this.currentTask.endTime = attach.endTime;
      this.currentTask.noticeInfo = attach.noticeInfo;
      this.currentTask.hasNotice = attach.notices.length > 0;
      await taskUpdate(this.currentTask);
      let addList = [];
      let deleteList = [];
      this.attach.notices.forEach(item => {
        let hasSome = attach.notices.some(subItem =>
          item.mode == subItem.mode &&
          item.day == subItem.day &&
          item.hour == subItem.hour &&
          item.minute == subItem.minute);
        if (!hasSome) {
          deleteList.push(item);
        }
      });
      attach.notices.forEach(item => {
        let hasSome = this.attach.notices.some(subItem =>
          item.mode == subItem.mode &&
          item.day == subItem.day &&
          item.hour == subItem.hour &&
          item.minute == subItem.minute);
        if (!hasSome) {
          addList.push(item);
        }
      });
      for (let i = 0; i < addList.length; i++) {
        const notice = addList[i];
        notice.taskIdStr = this.currentTask.taskIdStr;
        await noticeAdd(notice);
      }
      for (let i = 0; i < deleteList.length; i++) {
        const notice = deleteList[i];
        await noticeDelete(notice.taskNoticeIdStr);
      }
      this.notices = attach.notices;
      this.currentTask.hasNotice = this.notices && this.notices.length > 0;

      let repeat = attach.repeat;
      if (repeat && Object.keys(repeat).length > 0) {
        repeat.taskIdStr = this.currentTask.taskIdStr;
        await repeatAdd(repeat);
      } else if (this.attach.repeat && Object.keys(this.attach.repeat).length > 0) {
        await repeatDelete(repeat.taskRepeatIdStr);
      }
      this.repeat = repeat;
      this.currentTask = repeat && Object.keys(repeat).length > 0;

      let habit = attach.habit;
      if (habit && Object.keys(habit).length > 0) {
        habit.taskIdStr = this.currentTask.taskIdStr;
        habit.taskId = this.currentTask.taskId;
        habit.updateTime = new Date().getTime()
        await habitUpdate(habit);
      }

      this.loadNotices();
    },
    onTitleBlur() {
      taskUpdate(this.currentTask);
    },
    onMapSelected(value) {
      this.location = value;
    },
    onMapModalOk() {
      if (this.location.lng && this.location.lat) {
        addressAdd({
          positionWeight: 1,
          poiAddress: this.location.address,
          latitude: this.location.lat,
          longitude: this.location.lng,
          totalAddress: this.location.address,
          userId: this.$store.state.user.userId,
        }).then(res => {
          if (res) {
            addressList().then(res => {
              if (res) {
                this.$store.commit('updateAddresses', res);
              }
            });
            this.currentTask.addressIdStr = res;
            taskUpdate(this.currentTask);
          }
        });
      }
    },
    tagSelected(item) {
      return (this.currentTask.tagList || []).indexOf(item.tagIdStr) > -1;
    },
    onTagBoxClick(item) {
      if (!this.currentTask.tagList) {
        this.currentTask.tagList = [];
      }
      let index = this.currentTask.tagList.indexOf(item.tagIdStr);
      if (index > -1) {
        this.currentTask.tagList.splice(index, 1);
      } else {
        this.currentTask.tagList.push(item.tagIdStr);
      }
    },
    onTagChanged(visible) {
      if (!visible) {
        taskUpdate(this.currentTask);
      }
    },
    onFontClick() {
      this.$refs.richText.showTool();
    },
    onLineClick(type) {
      let map = {
        'line_solid': 'solid',
        'line_dotted': 'dashed',
        'line_point': 'dotted'
      }
      this.$refs.richText.addLine(map[type] || 'solid', this.lineColor);
    },
    onMoreClick(e) {
      this.$store.commit('updateMenuData', {
        event: e,
        task: this.currentTask
      });
    },
    // dayContentHook(arg) {
    //   let v = `<div class="dot" ></div>`;
    //   if (this.clockDates.includes(dayjs(arg.date).format("YYYYMMDD"))){
    //     v = `<div class="dot-active"></div>`;
    //   }
    //   if (this.clockGiveUpDate.includes(dayjs(arg.date).format("YYYYMMDD"))){
    //     v = `<div class="dot-giveup"></div>`;
    //   }
    //   return { html: v }
    // },
    // dayContentHook2(arg) {
    //   const startTime = dayjs(this.currentTask.startTime).format("YYYYMMDD");
    //   let endTime = this.currentTask.endTime ? dayjs(this.currentTask.endTime).format("YYYYMMDD"):dayjs().format("YYYYMMDD");
    //   if (dayjs().format("YYYYMMDD") < endTime){
    //     endTime = dayjs().format("YYYYMMDD");
    //   }
    //   const argTime = dayjs(arg.date).format("YYYYMMDD")
    //   let v = `<div class="circle" >${arg.dayNumberText}</div>`;
    //   if (argTime < startTime || argTime > endTime){
    //     v = `<div class="circle-normal">${arg.dayNumberText}</div>`;
    //   }else{
    //     if (this.clockDates.includes(argTime)){
    //       v = `<div class="circle-active">${arg.dayNumberText}</div>`;
    //     }
    //     if (this.clockGiveUpDate.includes(argTime)){
    //       v = `<div class="circle-giveup">✖</div>`;
    //     }
    //   }
    //   return { html: v }
    // },
    dateClick(arg){
      const argTime = dayjs(arg.date).format("YYYYMMDD")
      if (argTime >= this.taskStart && argTime <= this.taskEnd){
        this.updateClock(this.currentTask, arg.date, true)
      }
    },
    onPrevClick() {
      this.calendar = this.$refs.calendarM.calendar;
      this.calendarApi = this.$refs.calendarM.getApi();
      this.calendarApi.prev();
      this.showTitle = this.calendar.view.title;
    },
    onNextClick() {
      this.calendar = this.$refs.calendarM.calendar;
      this.calendarApi = this.$refs.calendarM.getApi();
      this.calendarApi.next();
      this.showTitle = this.calendar.view.title;
    },
    async updateClock(task, time, needPop){
      console.log("时间",time)
      if (!time){
        time = dayjs().valueOf()
      }
      //判断是否完成今日的打卡
      //没有完成  根据配置直接更新打卡，不用弹框 采用add方式
      let lastItem = this.todayLastItem(task, time);
      let clock = {
          "clockedMood": "",//打卡表情   cry哭  nervous紧张  normal正常  good好的 happy高兴
          "completeNum": 1,
          "clockedLog": "",
          "clockedDetail": "[{\"content\":\"1/1次\",\"time\":1694620651063}]",
          "giveUp": false,
          "updateTime": new Date().getTime(),
      }
      if (lastItem){
        clock.clockedHistoryId = lastItem.clockedHistoryId;
        clock.clockedHistoryIdStr = lastItem.clockedHistoryIdStr;
        clock.totalNum = lastItem.totalNum;
        clock.time = lastItem.time
        clock.completeNum = lastItem.completeNum
        clock.clockedMood = lastItem.clockedMood
        clock.clockedLog = lastItem.clockedLog
        clock.clockedDetail = lastItem.clockedDetail
      }else{
        clock.totalNum = task.habitDTO.habitDayNum;
        clock.time = dayjs(time).valueOf();
      }

      clock.taskIdStr = task.taskIdStr;
      clock.taskId = task.taskId;
      if(this.todayPercent(task) != 100){
        //配置分为3种
        if (task.habitDTO.habitType == 0){
          if (lastItem){
            if (needPop){
              this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
              this.$store.commit('updateShowDialog', true);
              return
            }
            clock.completeNum = clock.completeNum + task.habitDTO.habitOnceNum
            if (clock.completeNum > clock.totalNum){
              clock.completeNum = clock.totalNum;
            }
            if (clock.completeNum == clock.totalNum){
              clock.complete = true;
            }else{
              clock.complete = false;
            }
            const array = JSON.parse(lastItem.clockedDetail)
            array.push({content:`${clock.completeNum}/${clock.totalNum}${task.habitDTO.habitUnit}`,
              time:dayjs().valueOf()})
            clock.clockedDetail = JSON.stringify(array);
            await clockedUpdate(clock)
          }else{
            if (needPop){
              this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
              this.$store.commit('updateShowDialog', true);
              return
            }
            clock.completeNum = task.habitDTO.habitOnceNum
            if (clock.completeNum > clock.totalNum){
              clock.completeNum = clock.totalNum;
            }
            if (clock.completeNum == clock.totalNum){
              clock.complete = true;
            }else{
              clock.complete = false;
            }
            const array = []
            array.push({content:`${clock.completeNum}/${clock.totalNum}${task.habitDTO.habitUnit}`,
              time:dayjs().valueOf()})
            clock.clockedDetail = JSON.stringify(array);
            await clockedAdd(clock)
          }
        }else if (task.habitDTO.habitType == 1){
          this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
          this.$store.commit('updateShowDialog', true);
          return
        }else if (task.habitDTO.habitType == 2){
          if (lastItem){
            if (needPop){
              this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
              this.$store.commit('updateShowDialog', true);
              return
            }
            clock.completeNum = clock.totalNum;
            clock.complete = true;
            const array = JSON.parse(lastItem.clockedDetail)
            array.push({content:`${clock.completeNum}/${clock.totalNum}${task.habitDTO.habitUnit}`,
              time:dayjs().valueOf()})
            clock.clockedDetail = JSON.stringify(array);
            await clockedUpdate(clock)
          }else{
            if (needPop){
              this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
              this.$store.commit('updateShowDialog', true);
              return
            }
            clock.completeNum = clock.totalNum;
            clock.complete = true;
            const array = []
            array.push({content:`${clock.completeNum}/${clock.totalNum}${task.habitDTO.habitUnit}`,
              time:dayjs().valueOf()})
            clock.clockedDetail = JSON.stringify(array);
            await clockedAdd(clock)
          }
        }
        //1自动按照量打卡 每次都是add一个配置量的打卡
        //2手动指定量打卡  弹框输入量打卡  add
        //3全部完成 每次都是add或者update一个全部量的打卡

        if(task.habitDTO.autoShowLog){//弹框
          this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
          this.$store.commit('updateShowDialog', true);
        }
      }else{
        this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
        this.$store.commit('updateShowDialog', true);
        return
      }
      //完成打卡的  根据当前的最后一次打卡记录做更新操作 弹出弹框
      //更新列表里的这个数据，刷新页面
      let copyData = JSON.parse(JSON.stringify(task));
      this.$store.commit('updateListFlag', {
        key: 'update',
        value: copyData
      });
    },
    todayLastItem(task, time){
      let date = dayjs(time).format('YYYYMMDD');
      let array = [];
      let item = null;
      if (task.taskType == 2 && task.clockedHistoryDTOList) {
        array = task.clockedHistoryDTOList.filter(item => {
          return dayjs(item.time).format('YYYYMMDD') == date;
        });
        if (array.length > 0){
          item = array[array.length-1]
        }
      }
      return item;
    },
    todayNum(task){
      const date = dayjs().format('YYYYMMDD');
      let array = [];
      let completeNum = 0;
      if (task.taskType == 2 && task.clockedHistoryDTOList){
        array = task.clockedHistoryDTOList.filter(item=>{
          return dayjs(item.time).format('YYYYMMDD')== date;
        });
        completeNum = array.reduce((pre,item)=>{
          return pre + item.completeNum;
        },0)
      }
      return completeNum;
    },
    todayPercent(task){
      if (task.taskType  == 2 ){
        let percent = this.todayNum(task) * 100 / task.habitDTO.habitDayNum;
        if(percent>100){
          percent = 100
        }
        return percent;
      }else{
        return 0;
      }
    },
    todayGiveUp(task){
      const date = dayjs().format('YYYYMMDD');
      let array = [];
      if (task.taskType == 2 && task.clockedHistoryDTOList){
        array = task.clockedHistoryDTOList.filter(item=>{
          return dayjs(item.time).format('YYYYMMDD')== date;
        });
        return array.some(item=>{
          let records = []
          try {
            records = JSON.parse(item.clockedDetail);
          }catch (e){
            console.log(e)
          }
          if (records.length){
            const content = records[records.length-1].content;
            if (content =='放弃了打卡'){
              return true;
            }
          }
        })
      }
      return false;
    }
  },
  watch: {
    currentTask() {
      this.loadNotices();
      this.searchSubTask();
      if (this.currentTask.taskType == 2){
        this.calendarYearOptions=  {
          schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
          headerToolbar: false,
          multiMonthMinWidth: 100,
          multiMonthMaxColumns:6,
          height: 'auto',
          contentHeight: 100,
          plugins: [multiMonthPlugin],
          initialView: 'multiMonthYear',
          events: [],
          fixedWeekCount:false,
          locale: "zh-cn",
        };
        this.calendarMonthOptions= {
          schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
          headerToolbar: false,
          height: 'auto',
          plugins: [dayGridPlugin,interactionPlugin,timelinePlugin],
          initialView: 'dayGridMonth',
          events: [],
          fixedWeekCount:false,
          locale: "zh-cn",
          editable: true,
          showNonCurrentDates: false,
          dateClick: this.dateClick,
        };
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .task-detail {
    background-color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 3px;
    .detail-header {
      :deep(.ivu-input) {
        color: #000;
        font-weight: 600;
      }
      padding: 21px 20px 8px 20px;
      padding-bottom: 12px;
      // border-bottom: 1px solid rgba(205, 205, 205);
      .icon-bg {
        padding: 0px 4px;
        border-radius: 6px;
        // background: #FAFAFA;
        height: 24px;
      }
      .project-btn {
        padding: 4px 10px;
        // background-color: #fafafa;
        border-radius: 6px;
        // color: #979EA7;
        font-size: 14px;
        max-width: 200px;
        text-overflow: ellipsis;
      }
      .sub-title {
        color: #3D87DA;
        font-size: 13px;
      }
      .check {
        &:hover {
          background-color: lightgray;
          border-radius: 3px;
        }
      }
    }
    .size-bg {
      padding: 6px;
      border: 1px solid #E5E5E5;
      background-color: #FAFAFA;
      text-align: center;
      border-radius: 6px;
      cursor: pointer;
      &.select {
        border-color: transparent;
        background-color: #BDDBFC;
      }
    }
    .color-item {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      cursor: pointer;
      &.select {
        border: 3px solid #BDDBFC;
      }
    }
    .sub-task {
      background-color: #fafafa;
      border-radius: 8px;
      margin: 20px;
      :deep(.ivu-input) {
        background-color: #fafafa;
        resize: none;
      }
    }

    .title {
      :deep(.ivu-input) {
        resize: none;
      }
    }
    .calendar-year {
      :deep(.fc .fc-multimonth){
        border: none!important;
      }
      :deep(.fc-multimonth-header-table){
        display: none;
      }
      :deep(.fc .fc-multimonth-daygrid){
        background: transparent!important;
      }
    }
    :deep(.fc .fc-day-disabled){
      background: transparent!important;
    }
    :deep(.dot-active) {
      width: 10px;
      height: 10px;
      background: var(--el-color-primary);
      border-radius: 50%;
    }
    :deep(.dot) {
      width: 10px;
      height: 10px;
      background: #ccc;
      border-radius: 50%;
    }
    :deep(.dot-giveup) {
      width: 10px;
      height: 10px;
      background: #333;
      border-radius: 50%;
    }
    :deep(.fc-daygrid-day-top){
      justify-content: center;
    }
    :deep(.fc-daygrid-day-events){
      display: none;
    }
    :deep(.fc-theme-standard td){
      border: none!important;
    }
    :deep(.fc-theme-standard th){
      border: none!important;
    }
    :deep(.fc-theme-standard .fc-scrollgrid){
      border: none!important;
    }
    :deep(.fc .fc-scrollgrid-section-sticky > *){
      background: transparent!important;
      position: static;
    }
    :deep(.circle-active) {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 5px solid var(--el-color-primary);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    :deep(.circle) {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 5px solid #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    :deep(.circle-normal) {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 5px solid transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ccc;
      cursor: default;
    }
    :deep(.circle-giveup){
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 3px solid #aaa;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      color: #aaa;
      font-size: 30px;
      font-weight: bold;
    }
  }
</style>
