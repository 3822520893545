<template>
  <DropdownMenu :style="'width: '+menuWidth+'px'">
    <template 
      v-for="item in dataList" 
      :key="item.tagIdStr" >
      <Dropdown 
        v-if="item.list && item.list.length > 0" 
        placement="right-start">
        <DropdownItem @click.stop="onSelect(item)">
          <Checkbox :modelValue="isSelected(item.tagIdStr)" @click.stop="onSelect(item)"></Checkbox>
          <Text :style="'width: '+(menuWidth-67)+'px'" ellipsis>{{ item.tagName }}</Text>
          <Icon type="ios-arrow-forward"></Icon>
        </DropdownItem>
        <template #list>
          <XTagMenu
            :menuWidth="menuWidth"
            :onSelect="onSelect"
            :dataList="item.list"
            :selectedList="selectedList"/>
        </template>
      </Dropdown>
      <DropdownItem v-else @click.stop="onSelect(item)">
        <Checkbox :modelValue="isSelected(item.tagIdStr)" @click.stop="onSelect(item)"></Checkbox>
        <Text :style="'width: '+(menuWidth-67)+'px'" ellipsis>{{ item.tagName }}</Text>
      </DropdownItem>
    </template>
  </DropdownMenu>
</template>

<script>
  export default {
    name: 'XTagMenu',
    props: {
      menuWidth: {
        type: Number,
        default: 142
      },
      onSelect: {
        type: Function,
        default: () => {}
      },
      dataList: {
        type: Array,
        default: () => []
      },
      selectedList: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      isSelected(tagId) {
        if (this.selectedList) {
          return this.selectedList.indexOf(tagId) > -1;
        }
        return false;
      }
    }
  }
</script>