<template>
  <div :class="{'calendar-component': true, 'black': currentTheme=='black'}">
    <div class="content-header">
      <ButtonGroup style="margin-right: 8px;">
        <Button
          @click="onPrevClick"
          class="common-button"
          style="width: 28px;">
          <XIcon icon-class="arrow_left_white" size="20" style="margin-bottom: 2px;" v-if="currentTheme == 'black'"/>
          <XIcon icon-class="arrow_left" size="20" style="margin-bottom: 2px;" v-else/>
        </Button>
        <Button
          @click="onNextClick"
          class="common-button"
          style="width: 28px;">
          <XIcon icon-class="arrow_right_white" size="20" style="margin-bottom: 2px;" v-if="currentTheme == 'black'"/>
          <XIcon icon-class="arrow_right_black" size="20" style="margin-bottom: 2px;" v-else/>
        </Button>
      </ButtonGroup>
      <Button
        size="small"
        @click="onTodayClick"
        class="common-button">
        <XIcon icon-class="jin_white" size="16" style="margin-bottom: 4px;" v-if="currentTheme == 'black'"/>
        <XIcon icon-class="jin" size="16" style="margin-bottom: 4px;"  v-else/>
      </Button>
      <span style="flex: 1"></span>
      <span style="font-size: 18px;">{{ showTitle }}</span>
      <span style="flex: 1"></span>
      <!-- <Space>
        <Switch size="small" :model-value="showComplete" @on-change="onSwitchChange" />
        <span style="font-size: 14px;">显示已完成</span>
        <span style="color: #CCC; margin-left: 5px;">|</span>
      </Space> -->
      <div class="button-group">
        <Button
          v-for="item in typeData"
          :key="item.type"
          size="small"
          :class="'common-button '+(type==item.type?'button-high':'button-normal')"
          @click="onTagClick(item)"
        >{{ item.name }}</Button>
      </div>
      <Dropdown trigger="click" placement="bottom-end">
        <XIcon icon-class="more-col_white" style="font-size: 16px;-webkit-app-region: no-drag;" v-if="currentTheme == 'black'"/>
        <XIcon icon-class="more-col" style="font-size: 16px;-webkit-app-region: no-drag;" v-else/>
        <template #list>
          <DropdownMenu>
            <DropdownItem
              @click="onMoreClick">
              {{ showComplete ? '隐藏已完成' : '显示已完成' }}
            </DropdownItem>
            <!-- <DropdownItem
                @click="changeTheme">
              {{ currentTheme == 'black' ? '正常主题' : '暗黑主题' }}
            </DropdownItem> -->
          </DropdownMenu>
        </template>
      </Dropdown>
    </div>
    <div class="calendar-bg">
      <FullCalendar ref="calendar" :options="calendarOptions" >
        <template #eventContent='arg'>
          <div style="display: flex;flex-direction: row;align-items: center;overflow: hidden"
            :style="{
              color: currentTheme === 'black' ? 'white' : '',
              opacity: arg.event.backgroundColor === '#D6D6D6' ? 0.4 : 1
            }"
               @click="handleEventClick(arg)">
            <div class="dot" :style="{borderColor: arg.event.backgroundColor}"></div>
            <div style="margin-right: 5px" v-if="arg.timeText" >{{ arg.timeText  }}</div>
            <div style="font-weight: 700;">{{ arg.event.title }}</div>
          </div>
        </template>
      </FullCalendar>
      <!-- <Button class="show prev" shape="circle" icon="ios-arrow-back" @click="onPrevClick"></Button>
      <Button class="show next" shape="circle" icon="ios-arrow-forward" @click="onNextClick"></Button> -->
    </div>
    <Modal
      v-if="showModal"
      :mask-closable="false"
      width="520px"
      :closable="false"
      v-model="showModal"
      @on-ok="onModalOk">
      <XToastTaskDetail :task="task" />
    </Modal>
  </div>
</template>

<script>
  import XToastTaskDetail from '@/components/XToastTaskDetail';
  import calenderUtil from "@/common/calendar"

  import FullCalendar from '@fullcalendar/vue3'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import listPlugin from '@fullcalendar/list'
  import momentPlugin from '@fullcalendar/moment'
  import timelinePlugin from '@fullcalendar/timeline';
  import multiMonthPlugin from '@fullcalendar/multimonth';
  import { HolidayUtil } from 'lunar-javascript'

  import {
    userInfo,
    taskList,
    taskAdd,
    taskUpdate,
    noticeAdd,
    repeatAdd,
    stickerList
  } from '@/common/api';

  // let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of toda

  const _typeData = {

    'month': {
      type: 'month',
      name: '月',
      key: 'dayGridMonth'
    },
    'week': {
      type: 'week',
      name: '周',
      key: 'timeGridWeek'
    },
    'day': {
      type: 'day',
      name: '日',
      key: 'timeGridDay'
    }
  };

  let _colorMap = {};

  export default {
    props: {
      modelValue: {
        type: String,
        default: 'month',
      },
      theme: {
        type: String,
        default: 'black'
      }
    },
    emits: ['update:modelValue'],
    components: {
      XToastTaskDetail,
      FullCalendar
    },
    mounted() {
      this.calendar = this.$refs.calendar.calendar;
      this.calendarApi = this.$refs.calendar.getApi();
      setTimeout(() => {
        this.onTodayClick();
      }, 100);
      this.loadData();
      this.currentTheme = this.theme
    },
    data() {
      let initialType = 'dayGridMonth';
      if (Object.keys(_typeData).indexOf(this.modelValue) > -1) {
        initialType = _typeData[this.modelValue].key;
      }
      return {
        currentTheme:'',
        target: 'all',
        menuList: [{
          'title': '显示已完成',
          'id': 1
        }],

        showModal: false,
        type: this.modelValue,
        typeData: _typeData,
        showTitle: '',
        calendar: {},
        calendarApi: {},
        showComplete: false,
        calendarOptions: {
          firstDay: localStorage.getItem("weekBegin") || 0,
          schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
          height: '100%',
          plugins: [ timelinePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, momentPlugin, multiMonthPlugin ],
          headerToolbar: null,
          allDayText: '全天',
          allDaySlot: true,
          slotEventOverlap: false, // 任务是否重叠
          slotLabelFormat: {
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
            hour12: false // 设置时间为24小时
          },
          initialView: initialType,
          events: [],
          eventTimeFormat: 'HH:mm',
          slotDuration: '00:30:00',
          slotLabelInterval: '01:00',
          locale: 'zh-cn',
          editable: true,
          selectable: true,
          selectMirror: true,
          dayMaxEvents: true,
          weekends: true,
          dayCellContent: this.dayContentHook,
          select: this.handleDateSelect,
          eventClick: this.handleEventClick,
          eventDrop: this.handleEventDrop,
          eventResize: this.handleEventResize,
          eventsSet: this.handleEvents,
          moreLinkContent: this.moreContentHook,
          // moreLinkClick: this.handleMoreClick // more按钮点击 可以返回类型string或者function
          eventContent: this.eventContentHook,
        },
        normalList: [],
        completeList: [],

        task: {}
      }
    },
    computed: {
      showList() {
        if (this.showComplete) {
          return [...this.normalList, this.completeList];
        }
        return this.normalList;
      },
      color(){
        return this.currentTheme == 'black'?'#F0F0F0':''
      },
      headerColor(){
        return this.currentTheme == 'black'?'#D4D4D4':''
      },
      headerBg(){
        return this.currentTheme == 'black'?'#212121':''
      }
    },
    methods: {
      onMoreClick() {
        this.onSwitchChange(!this.showComplete);
      },
      onTagClick(item) {
        // this.$router.replace('/manager/calendar/' + item.type);
        this.$emit('update:modelValue', item.type);
        this.type = item.type;
        this.calendar.changeView(item.key);
        this.onTodayClick();
      },
      dayContentHook(arg) {
        if (this.type == 'month') {
          let date = arg.date
          let text = date.getDate()
          let lunar = calenderUtil.solar2lunar(date.getFullYear(), date.getMonth() + 1, date.getDate())
          let iDay = lunar.lDay == 1 ? lunar.IMonthCn : lunar.IDayCn
          let subTitle = lunar.festival ? lunar.festival : iDay
          let holiday = HolidayUtil.getHoliday(date.getFullYear(), date.getMonth() + 1, date.getDate())
          let hv = ''
          if (holiday) {
            let isWork = holiday.isWork();
            let color = isWork ? '#43CF7C' : '#FD6C6C';
            let text = isWork ? '班' : '休';
            hv = `<span style="color:white;font-size:12px;width:16px;height:16px;line-height:16px;border-radius:8px;background-color:${color};position:absolute;top:5px;right:5px;text-align:center;">${text}</span>`
          }
          let style = 'display:flex;justify-content:space-between;width:100%;padding:18px 6px 6px 6px;'
          let dateStyle = ''
          if (arg.isToday) {
            dateStyle = 'padding:2px 6px;border-radius:12px;background-color:#579CF9;color:white;';
          }
          let v = `<div style="${style}"><span style="${dateStyle}">${text}</span><span style="color:#999999;">${subTitle}</span>${hv}</div>`;
          return { html: v }
        }
      },
      handleDateSelect(selectInfo) {
        this.showModal = true;
        this.task = {
          giveUp: false,
          completeTime: 0,
          title: '',
          level: 0,
          projectIdStr: '1' + this.$store.state.user.userId,
          positionWeight: 10,
          taskType: 0,
          startTime: selectInfo.start.getTime(),
          endTime: selectInfo.end.getTime()
        };

        let calendarApi = selectInfo.view.calendar;
        calendarApi.unselect();
        // console.log(selectInfo);
        // let title = prompt('输入任务名称')
        // let calendarApi = selectInfo.view.calendar

        // calendarApi.unselect() // clear date selection

        // if (title) {
        //   calendarApi.addEvent({
        //     id: ++this.index,
        //     title,
        //     start: selectInfo.startStr,
        //     end: selectInfo.endStr,
        //     allDay: selectInfo.allDay
        //   })
        // }
      },
      handleEventClick(clickInfo) {
        console.log("handleEventClick",clickInfo)
        this.showModal = true;
        this.task = Object.assign({}, clickInfo.event.extendedProps);
      },
      handleEventDrop(eventDropInfo) {
        let event = eventDropInfo.event;
        let task = Object.assign({}, event.extendedProps);

        task.startTime = event.start.getTime();
        task.originTime = task.startTime;
        if (event.end) {
          task.endTime = event.end.getTime();
        } else {
          task.endTime = task.startTime + 60 * 60 * 1000; // 如果没有结束时间，默认结束时间为一个小时
        }
        taskUpdate(task);
      },
      handleEventResize(eventResizeInfo) {
        let event = eventResizeInfo.event;
        let task = Object.assign({}, event.extendedProps);

        task.startTime = event.start.getTime();
        task.originTime = task.startTime;
        task.endTime = event.end.getTime();
        taskUpdate(task);
      },
      handleEvents(events) {
        this.currentEvents = events
      },
      onPrevClick() {
        this.calendarApi.prev();
        this.showTitle = this.calendar.view.title;
      },
      onNextClick() {
        this.calendarApi.next();
        this.showTitle = this.calendar.view.title;
      },
      onTodayClick() {
        this.calendarApi.today();
        this.showTitle = this.calendar.view.title;
      },
      handleMoreClick(info) {
        console.log(info);
        return null;
        // return 'timeGridDay';
      },
      moreContentHook(arg) {
        return '+' + arg.num + ' 更多';
      },
      eventContentHook(arg) {
        let startTime = arg.event.extendedProps.startTime;
        if (startTime) {
          let timeText = new Date(startTime).format("hh:mm");
          arg.timeText = timeText == '00:00' ? '' : timeText;
        }
      },
      loadData() {
        userInfo().then(info => {
          if (info && info.userId) {
            this.$store.commit("updateInfo", info);
            let projectId = '-2' + info.userId;
            taskList(projectId).then(res => {
              if (res) {
                this.completeList = [];
                this.normalList = [];
                res.forEach(element => {
                  if (element.startTime) {
                    let obj = {
                      id: element.taskIdStr,
                      title: element.title,
                      start: element.startTime,
                      end: element.endTime,
                      extendedProps: element,
                      allDay: element.endTime - element.startTime > 24 * 60 * 60 * 1000,
                    };
                    if (element.completeTime > 0 || element.giveUp) {
                      obj.color = '#D6D6D6';
                      this.completeList.push(obj);
                    } else {
                      let color = _colorMap[element.taskIdStr];
                      if (!color) {
                        const r = Math.floor(Math.random()*256);
                        const g = Math.floor(Math.random()*256);
                        const b = Math.floor(Math.random()*256);
                        color = `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
                        _colorMap[element.taskIdStr] = color;
                      }
                      obj.color = color;
                      this.normalList.push(obj);
                    }
                  }
                });
                if (this.showComplete) {
                  this.calendarOptions.events = [...this.normalList, ...this.completeList];
                } else {
                  this.calendarOptions.events = this.normalList;
                }
              }
            });
            stickerList().then((res) => {
              console.log(res);
            })
          }
        });
      },
      taskTitle(task) {
        if (!task.content) return '';
        let list = JSON.parse(task.content);
        for (let i = 0; i < list.length; i++) {
          const item = list[i];
          if (item.spanList && item.spanList.length > 0) {
            for (let j = 0; j < item.spanList.length; j++) {
              const subItem = item.spanList[j];
              if (subItem.content && subItem.content.length > 0) {
                return subItem.content;
              }
            }
          }
        }
        return '';
      },
      onSwitchChange(value) {
        this.showComplete = value;
        if (value) {
          this.calendarOptions.events = [...this.normalList, ...this.completeList];
        } else {
          this.calendarOptions.events = this.normalList;
        }
      },
      onModalOk() {
        if (!this.task.taskIdStr) {
          taskAdd(this.task).then(res => {
            if (res) {
              if (this.task.notices && this.task.notices.length > 0) {
                this.task.notices.forEach(element => {
                  element.taskIdStr = res.taskIdStr;
                  noticeAdd(element);
                });
              }
              if (this.task.repeat && Object.keys(this.task.repeat).length > 0) {
                this.task.repeat.taskIdStr = res.taskIdStr;
                repeatAdd(this.task.repeat);
              }
              this.loadData();
            }
          });
        } else {
          this.loadData();
        }
      },
      changeTheme(){
        if(this.currentTheme == 'black'){
          this.currentTheme = ''
        }else{
          this.currentTheme = 'black'
        }
      }
    }
  }
</script>

<style lang="less" scoped>
@headerColor: v-bind(headerColor);
@headerBg: v-bind(headerBg);
@color: v-bind(color);

:deep(.fc-more-link){
  color:@color
}
:deep(.fc-col-header-cell-cushion){
  color:@headerColor
}
:deep(thead .fc-scrollgrid-sync-inner){
  background:@headerBg
}
:deep(.fc-daygrid-day-number span){
  color:@color
}

:deep(.fc-popover-body) {
  max-height: 300px;
  overflow-y: scroll;
}
:deep(.fc-daygrid-day-number){
  width: 100%;
}
.calendar-component {
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
    .content-header {
      padding: 20px;
      display: flex;
      cursor: move;
      -webkit-app-region: drag;
      .common-button {
        -webkit-app-region: no-drag;
        padding: 0px;
        width: 40px;
        height: 24px;
        background-color: #E4EFFE;
        border: none;
      }
      .button-group {
        -webkit-app-region: no-drag;
        background-color: #E4EFFE;
        border-radius: 4px;
        height: 24px;
        margin-right: 10px;
        .ivu-btn-small {
          padding: 0 12px;
        }
        .button-normal {
          background-color: transparent;
          border-color: transparent;
          font-size: 12px;
        }
        .button-high {
          background-color: #ADC6FF;
          font-size: 12px;
        }
      }
    }
    .calendar-bg {
      -webkit-app-region: no-drag;
      height: calc(100% - 72px);
      padding: 0px 30px 30px 30px;
      position: relative;
      &:hover {
        .show {
          display: inline;
        }
      }
      button {
        position: absolute;
        display: none;
        top: calc(50% - 20px);
        background-color: lightgrey;
        color: white;
        text-align: center;
        font-size: 20px;
        z-index: 9999;
        &.prev {
          left: 20px;
        }
        &.next {
          right: 20px;
        }
      }
      .dot {
        margin: 0 4px;
        box-sizing: content-box;
        width: 0;
        height: 0;
        border: 4px solid #3788d8;
        border: calc(var(--fc-daygrid-event-dot-width, 8px)/2) solid var(--fc-event-border-color,#3788d8);
        border-radius: 4px;
        border-radius: calc(var(--fc-daygrid-event-dot-width, 8px)/2);
      }
    }
     &.black {
       background-color: rgba(0, 0, 0, 0.7);
       color: white;
       .common-button {
         background-color: #E4EFFE33;
       }
       .button-group {
         background-color: #E4EFFE33;
         //border: 1px solid #4d4d4d;
         .button-normal {
           color: white;
         }
         .button-high {
           color: #85A9FF;
           //background-color: #ADC6FF33;
           background-color: #2F3B59;
         }
       }
       --fc-small-font-size: .85em;
       --fc-page-bg-color: #000;
       --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
       --fc-neutral-text-color: #808080;
       --fc-border-color: #F2F3F733;

       --fc-button-text-color: #fff;
       --fc-button-bg-color: red;
       --fc-button-border-color: #2C3E50;
       --fc-button-hover-bg-color: #1e2b37;
       --fc-button-hover-border-color: #1a252f;
       --fc-button-active-bg-color: #1a252f;
       --fc-button-active-border-color: #151e27;

       --fc-event-bg-color: #3788d8;
       --fc-event-border-color: #3788d8;
       --fc-event-text-color: #fff;
       --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

       --fc-more-link-bg-color: #d0d0d0;
       --fc-more-link-text-color: inherit;

       --fc-event-resizer-thickness: 8px;
       --fc-event-resizer-dot-total-width: 8px;
       --fc-event-resizer-dot-border-width: 1px;

       --fc-non-business-color: rgba(215, 215, 215, 0.3);
       --fc-bg-event-color: rgb(143, 223, 130);
       --fc-bg-event-opacity: 0.3;
       --fc-highlight-color: #3D87DA40;
       // --fc-today-bg-color: rgba(255, 220, 40, 0.15);
       --fc-today-bg-color: #3D87DA12;
       --fc-now-indicator-color: red;
     }
  }
</style>
