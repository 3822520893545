<template>
  <div class="recycler-list">
    <div class="list-header">
      <Button size="large" type="text" @click="$router.go(-1)">
        <Icon type="ios-arrow-back" style="font-size: 24px" />
      </Button>
      <span style="font-size: 24px; margin-right: 8px; min-width: 72px;">回收站</span>
      <Cascader 
        placeholder="所有清单" 
        :data="projectList" 
        change-on-select 
        :render-format="renderFormat"
        @on-change="onProjectChange"
      />
      <Input 
        v-model="search"
        prefix="ios-search" 
        placeholder="搜索关键字" 
        style="width: auto; margin-left: 6px;"
        @on-enter="onSearch" 
      />
      <span style="flex: 1"></span>
      <Button size="large" type="text" @click="onClearClick">
        <Icon type="ios-arrow-back" style="font-size: 24px" />
        清空
      </Button>
    </div>
    <div class="list-content">
      <XRecyclerItem :data="taskList" />
    </div>
  </div>
</template>

<script>
import XRecyclerItem from './XRecyclerItem';
import { recycleProjectList, recycleTaskList, recycleTaskClean } from '@/common/api';

export default {
  components: {
    XRecyclerItem
  },
  data() {
    return {
      project: null,

      projectList: [],
      search: '',

      taskList: []
    }
  },
  mounted() {
    this.loadProject();
    this.loadTask();
  },
  methods: {
    loadProject() {
      recycleProjectList().then(res => {
        if (res) {
          this.projectList = this.packageData(res);
        }
      });
    },
    loadTask() {
      recycleTaskList(this.project).then(res => {
        if (res) {
          this.taskList = res;
        }
      });
    },
    packageData(list) {
      let normalList = [];
      list.forEach(item => {
        if (item.projectIdStr == '-1' + this.$store.state.user.userId || item.projectType == 1) return;
        normalList.push(item);
      });
      return this.dgTree(null, null, normalList);
    },
    // 组装树
    dgTree(id, name, list) {
      let children = [];
      list.forEach(item => {
        if (item.parentProjectIdStr === id) {
          item.value = item.projectIdStr;
          item.label = item.name;
          item.parentProjectName = name;
          item.children = this.dgTree(item.projectIdStr, item.name, list);
          children.push(item);
        }
      });
      return children.sort((a, b) => b.positionWeight - a.positionWeight);
    },
    renderFormat(labels) {
      if (labels.length > 0) {
        return labels[labels.length - 1];
      }
      return '';
    },
    onProjectChange(value) {
      if (value.length > 0) {
        this.project = value[value.length - 1];
      } else {
        this.project = null;
      }
      this.onSearch();
    },
    onSearch() {
      this.loadTask();
    },
    onClearClick() {
      this.$Modal.confirm({
        title: '清空垃圾桶',
        content: '<p>垃圾桶中的任务将被永久删除。</p><p>确认清空垃圾桶？</p>',
        onOk:() => {
          recycleTaskClean(this.project).then(res => {
            if (res) {
              this.loadData();
            }
          });
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
  .recycler-list {
    height: 100%;
    background-color: #fff;
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
    .list-header {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }
    .list-content {
      flex: 1;
      overflow-y: overlay;
    }
  }
</style>