<template>
  <transition name="fade">
    <div 
      v-if="showModal"
      class="download-mask" 
      @click="onMaskClick">
      <Space class="download-content">
        <a 
          class="download-btn"
          v-for="item in downloadList" 
          :key="item.image" 
          :href="item.url"
          target="__blank"
        >
          <XIcon :iconClass="item.image" style="font-size: 40px;"/>
          {{ item.title }}
        </a>
      </Space>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      showModal: this.$store.state.showDownload,
      downloadList: [{
        title: 'Android',
        image: 'android',
        url: 'https://pgyer.com/PqTe',
      }, {
        title: 'iOS',
        image: 'apple',
        url: 'https://apps.apple.com/cn/app/id1672171155',
      }, {
        title: 'Windows',
        image: 'windows',
        url: 'https://file.yimutodo.com/downloads/windows/yimutodo.exe',
      }, 
      {
        title: 'macOS',
        image: 'apple',
        url: 'https://file.yimutodo.com/downloads/mac/yimutodo.dmg',
      }]
    }
  },
  methods: {
    onMaskClick() {
      this.$store.state.showDownload = false
    }
  },
  watch: {
    '$store.state.showDownload'(value) {
      this.showModal = value
    }
  }
}
</script>

<style lang="less" scoped>

  .download-mask {
    display: flex;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    justify-content: center;
    z-index: 1999;
    .download-btn {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      // background-color: hsla(0,0%,100%,.07);
      background-color: #3D87DA75;
      color: hsla(0,0%,100%,.9);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        background-color: #3D87DAF0;
      }
    }
  }
</style>