<template>
  <div @contextmenu.prevent.stop="onRightClick">

  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  onMounted() {
    
    // let win = this.$electron.remote.getCurrentWindow()
    // let biasX = 0
    // let biasY = 0
    // let that = this;
    // document.addEventListener('mousedown', function (e) {
    //   switch (e.button) {
    //     case 0:
    //       biasX = e.x
    //       biasY = e.y
    //       document.addEventListener('mousemove', this.moveEvent);
    //       break
    //     case 2:
    //       that.$electron.ipcRenderer.send('createSuspensionMenu');
    //       break
    //   }
    // })
 
    // document.addEventListener('mouseup', function () {
    //     biasX = 0
    //     biasY = 0
    //     document.removeEventListener('mousemove', this.moveEvent)
    // })

  },
  methods: {
    onRightClick(e) {
      let items = [{
        label: '删除',
        onClick: () => this.onCloseClick
      }];
      e.preventDefault();
      this.$contextmenu({
        x: e.x,
        y: e.y,
        items: items
      });
    },
    onCloseClick() {

    },
    moveEvent() {
      
    }
  }
}
</script>