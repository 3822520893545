<template>
	<div class="timer-component">
    <div class="sub-component">
      <span class="title">正计时</span>
      <span class="time">{{ formatSeconds }}</span>
      <span style="flex: 1;"></span>
      <div class="no-drag">
        <Tooltip v-show="!isBegin" content="开始" placement="top" style="margin-right: 28px;">
          <p style="display: flex;align-items: center;cursor: pointer;" @click="onBegin">
            <XIcon iconClass="play_begin" size="24" style="margin-right: 6px;"/>
            <span style="color: rgba(61, 135, 218, 1);font-size: 14px;">开始</span>
          </p>
        </Tooltip>
        <Tooltip v-show="isBegin" :content="isPause ? '继续' : '暂停'" placement="top" style="margin-right: 12px;">
          <XIcon :iconClass="isPause ? 'play_begin' : 'play_pause'" size="24" @click="pasuseClick"/>
        </Tooltip>
        <Tooltip v-show="isBegin" content="结束" placement="top" style="margin-right: 20px;">
          <XIcon iconClass="play_done" size="24" @click="intervalEnd"/>
        </Tooltip>
      </div>
    </div>
	</div>
</template>

<script>
  import { absorbedAdd } from '@/common/api';

	export default {
		data() {
			return {
				tab: 'normal',
        
        isBegin: false,
        isPause: false,
        originSeconds: 0, // 初始的秒数
        seconds: 0,
        interval: {},
        intervalList: [], // 间隔列表

        searchShow: false,
        taskList: []
			}
		},
    computed: {
      formatSeconds() {
        let hours = Math.floor(this.seconds / 3600);
        let minutes = Math.floor(this.seconds % 3600 / 60);
        let seconds = this.seconds % 60;

        let showHours = hours < 10 ? '0' + hours : hours + '';
        let showMinutes = minutes < 10 ? '0' + minutes : minutes + '';
        let showSeconds = seconds < 10 ? '0' + seconds : seconds + '';
        if (hours == 0) {
          return `${showMinutes}:${showSeconds}`;
        } else {
          return `${showHours}:${showMinutes}:${showSeconds}`;
        }
      },
      relatedTitle() {
        if (this.taskList && this.taskList.length > 0) {
          let task = this.taskList[0];
          if (this.taskList.length == 1) {
            return task.title;
          } else {
            return task.title + `  等${this.taskList.length}条任务`;
          }
        }
        return '关联任务';
      }
    },
    methods: {
      onBegin() {
        if (this.tab == 'normal') {
          this.normalInterval();
        } else {
          if (this.originSeconds == 0) return;
          this.seconds = this.originSeconds;
          this.reverseInterval();
        }
        this.isBegin = true;
        this.insertStartTime();
      },
      normalInterval() {
        this.interval = setInterval(() => {
          this.seconds++;
        }, 1000);
      },
      reverseInterval() {
        this.interval = setInterval(() => {
          this.seconds--;
          if (this.seconds == 0) {
            this.intervalEnd();
            this.$Message.info('倒计时结束');
          }
        }, 1000);
      },
      intervalEnd() {
        let record = 0;
        if (this.tab == 'normal') {
          record = this.seconds;
        } else {
          record = this.originSeconds - this.seconds;
        }
        if (record < 60) {
          this.$Message.info('时间小于一分钟，已忽略本次专注');
        } else {
          this.insertEndTime();
          let startTime = 0;
          let endTime = 0;
          let totalTime = 0;
          for (let i = 0; i < this.intervalList.length; i++) {
            const element = this.intervalList[i];
            element.taskIds = this.taskList.map(e => e.taskIdStr)
            element.taskIdStrs = this.taskList.map(e => e.taskIdStr)            
            if (i == 0) startTime = element.startTime;
            if (i == this.intervalList.length - 1) endTime = element.endTime;
            totalTime += element.endTime - element.startTime;
          }
          let updateTime = endTime;
          let params = {
            absorbedInfoList: this.intervalList,
            complete: false,
            completeIds: [],
            completeIdStrs: [],
            endTime,
            mode: this.tab == 'normal' ? 1 : 2,
            startTime,
            totalTime,
            updateTime
          };
          absorbedAdd(params).then(res => {
            if (res) {
              this.$Message.success('恭喜，已完成本次专注');
            }
          });
        }
        this.seconds = 0;
        this.isBegin = false;
        this.isPause = false;
        this.intervalList = [];
        clearInterval(this.interval);
      },
      pasuseClick() {
        if (this.isPause) {
          this.isPause = false;
          this.insertStartTime();
          if (this.tab == 'normal') {
            this.normalInterval();
          } else {
            this.reverseInterval();
          }
          this.isBegin = true;
        } else {
          this.isPause = true;
          this.insertEndTime();
          clearInterval(this.interval);
        }
      },
      timeChange(value) {
        if (!value || value.length == 0) {
          this.originSeconds = 0;
        } else {
          let l = value.split(':');
          this.originSeconds = parseInt(l[0]) * 60 * 60 + parseInt(l[1]) * 60;
        }
      },
      onSelected(taskList) {
        this.taskList = taskList;
      },
      onRelateClick() {
        if (!this.isBegin) {
          this.searchShow = true;
        }
      },
      // 点击开始或者重启添加开始时间
      insertStartTime() {
        let nowTime = new Date().getTime();
        this.intervalList.push({
          'startTime': nowTime
        });
      },
      insertEndTime() {
        let nowTime = new Date().getTime();
        if (this.intervalList.length > 0) {
          let obj = this.intervalList[this.intervalList.length - 1];
          if (!obj.endTime) obj.endTime = nowTime;
        }
      },
    },
	}
</script>

<style lang="less" scoped>
	.timer-component {
		width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    -webkit-app-region: drag;
    .sub-component {
      width: 100%;
      height: 60px;
      border-radius: 4px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      .title {
        font-size: 14px;
        color: rgba(153, 153, 153, 1);
        margin-left: 16px;
      }
      .time {
        font-size: 16px;
        font-weight: 600;
        margin-left: 16px;
      }
      .no-drag {
        -webkit-app-region: no-drag;
        display: flex;
        align-items: center;
      }
    }
	}
</style>