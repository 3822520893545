<template>
  <Content class="work">
    <Split 
      v-model="taskSplit.split" 
      :min="taskSplit.min" 
      :max="taskSplit.max">
      <template #left>
        <XTaskList />
      </template>
      <template #trigger>
        <div class="trigger-bg">
          <div class="trigger"></div>
        </div>
      </template>
      <template #right>
        <XTaskDetail />
      </template>
    </Split>
  </Content>
</template>

<script>
import XTaskList from '@/components/XTaskList'
import XTaskDetail from '@/components/XTaskDetail'

export default {
  components: { 
    XTaskList, 
    XTaskDetail 
  },
  data() {
    return {
      taskSplit: {
        split: 0.5,
        min: 0.3,
        max: 0.3
      }
    }
  },
}
</script>

<style lang="less" scoped>
  .work {
    background-color: #f1f3ff;
    display: flex;
    .work-content {
      border-radius: 8px;
      background-color: #fff;
    }

    .trigger-bg {
      padding: 0 2px;
      height: 100%;
      .trigger {
        width: 1px;
        height: 100%;
        background-color: #f0f0f0;
        cursor: col-resize;
      }
    }
  }
</style>