<template>
  <div class="wx-login">
    <wxlogin
      appid="wxf569443760ff031e"
      scope="snsapi_login"
      theme="black"
      state="WECHAT"
      :redirect_uri="encodeURIComponent('https://yimutodo.com/#/loginCheck')"
    >
    </wxlogin>
  </div>
</template>

<script>
import wxlogin from "vue-wxlogin"

export default {
  components: {
    wxlogin
  }
}
</script>

<style lang="less" scoped>
  .wx-login {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>