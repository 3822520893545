<template>
  <XIcon 
    :icon-class="icon" 
    :style="{'font-size': size + 'px'}"
    @click.stop="onArrowClick" 
    :class="{'rotate-arrow': true, 'open': isOpen, 'show': show}" 
  />
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },   
    show: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'arrow_right'
    },
    size: {
      type: String,
      default: '16'
    }
  },
	emits: ['onArrowChanged'],
  data() {
    return {
      isOpen: this.$props.open
    }
  },
  methods: {
    onArrowClick() {
      this.isOpen = !this.isOpen
      this.$emit('onArrowChanged', this.isOpen);
    }
  }
}
</script>

<style lang="less" scoped>
  .rotate-arrow {
    opacity: 0;
    transition: 0.3s all;
    transform: rotate(0deg);
    &.open {
      transform: rotate(90deg);
    }
    &.show {
      opacity: 1;
    }
  }
</style>