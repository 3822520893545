<template>
  <ElDialog
    :modal="false"
    :width="620"
    v-model="showModal"
    @open="onOpen"
    @on-ok="onModalOk">
    <template #header>
      <p style="margin-bottom: 12px;">新增地点</p>
    </template>
    <div style="position: relative;">
      <div id="xMap"></div>
      <Select
        style="width: 100%; height: 48px; border-radius: 8px;z-index:9999; position: absolute;top: 0px;"
        clearable
        filterable
        placeholder="请输入地址"
        :remote-method="searchAddress"
        :loading="loading">
        <Option v-for="(address, index) in addressList" :value="address.id" :key="index" @click.stop="onOptionClick($event, address)">
          <span style="font-size: 14px; color: black;">{{ address.title }}</span>
          <span style="font-size: 12px; color: #A6A6A6; margin-left: 12px;">{{ address.address }}</span>
        </Option>
        <template #prefix>
          <XIcon style="margin: 14px 0px 0px 10px;" iconClass="search_gray" />
        </template>
      </Select>
      <div style="position: absolute; top: 50%; left: 50%; margin-left: -15px; margin-top: -15px;">
        <XIcon size="31" iconClass="location_red" />
      </div>
      <div class="address-box" @click="onModalOk">
        <p style="font-size: 14px; color: black;">
          <XIcon iconClass="location" color="#999999" style="margin-top: -2px;" />
          {{ centerAddress.name }}
        </p>
        <p style="font-size: 12px; color: #A6A6A6; margin-top: 4px; margin-left: 20px;">{{ centerAddress.address }}</p>
      </div>
    </div>
    <template #footer>
      <div style="display: flex; align-items: center; justify-content: right;">
        <i-button type="text" @click="showModal=false">取消</i-button>
        <i-button type="primary" shape="circle" @click="onModalOk">确定</i-button>
      </div>
    </template>
  </ElDialog>
</template>

<script>

import { shallowRef } from 'vue'
import { ElDialog } from 'element-plus';
import 'element-plus/es/components/dialog/style/css';

export default {
  setup() {
    const map = shallowRef(null)
    return{
      map,
    }
  },
  components: {
    ElDialog
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      showModal: false,

      mapSearch: {},

      loading: false,
      addressList: [],
      centerAddress: {},

      longitude: 116.397128,
      latitude: 39.916527,
    }
  },
  methods: {
    onOpen() {
      this.setMap();
    },
    getMyLocation() {
      var geolocation = new window.qq.maps.Geolocation("OX2BZ-LLMCJ-NABFD-DONPY-7QKIT-SJFB5", "yimutodo");
      geolocation.getLocation(this.showPosition, this.showErr);
    },
    showPosition(position) {
      this.latitude = position.lat;
      this.longitude = position.lng;
      if (this.map) {
        var myLatlng = new window.TMap.LatLng(this.latitude, this.longitude);
        this.map.setCenter(myLatlng);
      }
    },
    showErr() {
      console.log("定位失败");
    },
    setMap() {
      if (this.map) return;
      var element = document.getElementById("xMap");
      if (!element) return;
      var myLatlng = new window.TMap.LatLng(this.latitude, this.longitude)
      var myOptions = {
        zoom: 10, 
        center: myLatlng,
      }
      this.map = new window.TMap.Map(element, myOptions);
      this.map.on("panend", this.getAddress)
      this.map.on("zoomend", this.getAddress)
      this.getAddress();
      // this.marker = new window.TMap.MultiMarker({
      //   map: this.map, //指定地图容器
      //   styles: {
      //     // 点标记样式
      //     marker1: new window.TMap.MarkerStyle({
      //       width: 20, // 样式宽
      //       height: 30, // 样式高
      //       anchor: { x: 10, y: 30 }, // 描点位置
      //     }),
      //   },
      //   // 点标记数据数组
      //   geometries: [
      //     {
      //       id: 'demo',
      //       styleId: 'marker1', // 和上面styles里面的marker1 向对应
      //       position: myLatlng, // 标点的位置
      //       properties: {
      //         title: 'marker',
      //       },
      //     },
      //   ],
      // });
      // var infoWindow = new window.TMap.InfoWindow({
      //   map: this.map,
      //   enableCustom: true,
      //   position: myLatlng,
      //   // offset: { y: -70, x: -5 },
      //   content: '<div class="info_card"><div class="title"><span class="title_name">腾讯北京总部大楼</span><div class="close_img" onclick="closeInfoWindow()"><span class="min top_img"></span><span class="min right_img"></span><span class="min bottom_img"></span><span class="min left_img"></span></div></div><div align="left" class="content">点击右键变更内容</div><span class="cancle bot"></span><span class="cancle top"></span></div>'
      // });
      // var openInfoWindow = () => infoWindow.open()
      // // var closeInfoWindow = () => infoWindow.close()
      // this.marker.on('click', openInfoWindow);
    },
    getAddress() {
      var geocoder = new window.TMap.service.Geocoder();
      var mapCenter = this.map.getCenter();
      console.log(mapCenter);
      console.log("当前地图中心为: " + mapCenter.getLat().toFixed(6) + "," + mapCenter.getLng().toFixed(6));
      var location = new window.TMap.LatLng(mapCenter.lat, mapCenter.lng)
      geocoder.getAddress({ location: location }).then((result) => {
        var address = result.result.formatted_addresses;
        this.centerAddress = {
          lng: mapCenter.getLng().toFixed(6),
          lat: mapCenter.getLat().toFixed(6),
          name: address.recommend,
          address: address.standard_address
        }
      });
    },
    searchAddress(keyword) {
      if (!keyword || keyword.length == 0) return;
      this.loading = true;
      var search = new window.TMap.service.Search({ pageSize: 10 });
      search.searchRectangle({
        keyword,
        bounds: this.map.getBounds()
      }).then((result) => {
        this.loading = false;
        if (result.status == 0) {
          this.addressList = result.data;
        }
      });
    },
    onOptionClick(event, address) {
      event.preventDefault();
      this.map.setCenter(address.location);
    },
    onModalOk() {
      this.showModal = false;
      this.$emit('onModalOk', this.centerAddress);
    },
  },
  watch: {
    modelValue(value) {
      this.showModal = value;
    },
    showModal(value) {
      this.$emit('update:modelValue', value);
    }
  }
}
</script>

<style lang="less" scoped>
  #xMap {
    width: 100%;
    height: 100%;
    height: 400px;
    min-width: 300px;
    border-radius: 8px;
    top: 56px;
  }
  :deep(.ivu-select-selection) {
    border-radius: 8px;
    height: 48px !important;
  }
  :deep(.ivu-select-input) {
    top: 7px !important;
  }
  .address-box {
    padding: 6px 0px;
    margin: 56px 3px 3px 3px;
    cursor: pointer;
    &:hover {
      background-color: #ECF1F7;
    }
  }
</style>