<template>
  <div class="primary">
    <div class="intro">
      <transition name="slide-left">
        <div class="info" v-show="show">
          <h1>让待办更简单</h1>
          <p class="subtitle">使用一木清单，高效记录一切，轻松规划生活~</p>
          <Space size="large">
            <div class="intro-button normal" @click="downloadClick">立即下载</div>
            <div class="intro-button primary" @click="continueClick">使用网页版</div>
          </Space>
        </div>
      </transition>
      <transition name="slide-right">
        <div class="image" v-show="show">
          <img src="../assets/intro.png" alt="介绍">
        </div>
      </transition>
    </div>
    <div class="desc">
      <h3 style="font-weight: 400;">功能简介</h3>
      <p class="desc-subtitle">这是一款集任务与笔记功能于一体的清单管理工具，希望帮助你不惧遗忘、事半功倍~<br/>你可以用一木清单多端记录待办、记录笔记，通过日历、四象限、地图等多种视图规划并调整任务，通过数据统计，分析和回顾个人成长轨迹，你还可以和同伴共享协作，分享文件，共同完成一桩大事或一个令你快乐的目标~</p>
      <Grid :border="false" :col="colCount" padding="0px">
        <GridItem>
          <div class="desc-card">
            <img src="../assets/microphone.png" alt="microphone">
            <h6 style="margin-top: -16px; margin-bottom: 18px">快速记录</h6>
            <Text>文字输入、语音识别、音频录制等多种快捷方式帮助你快速记录生活中的点滴、灵感与待办任务。</Text>  
          </div>  
        </GridItem>
        <GridItem>
          <div class="desc-card">
            <img src="../assets/calendar.png" alt="calendar">
            <h6 style="margin-top: -16px; margin-bottom: 18px">日历视图</h6>
            <Text>日、周、月视图帮助你清晰规划你的一天、一周、一月，直观安排日程事项、准时提醒，助你提前做好准备。</Text>  
          </div>  
        </GridItem>
        <GridItem>
          <div class="desc-card">
            <img src="../assets/goal.png" alt="goal">
            <h6 style="margin-top: -16px; margin-bottom: 18px">保持专注</h6>
            <Text>不管是正、倒计时或番茄钟的专注方式，普通或严格的专注模式，或是多种背景白噪音，都为帮助你保存专注。</Text>  
          </div>  
        </GridItem>
        <GridItem>
          <div class="desc-card">
            <img src="../assets/point.png" alt="point">
            <h6 style="margin-top: -16px; margin-bottom: 18px">任务地图</h6>
            <Text>想知道你在某个地方是否有任务待办？进任务地图看看吧~对了，用任务地图做旅游清单攻略特别nice~</Text>  
          </div>  
        </GridItem>
      </Grid>
    </div>
    <div class="slogan">
      <p>事情再多也不怕</p>
      <h3 style="font-weight: 400">做你人生的自我掌舵者</h3>
      <h3 style="margin-bottom: 64px">轻松起航！</h3>
      <a @click="continueClick" style="font-size: 26px;">现在就试用 →</a>
    </div>
    <div class="primary-footer">
      <div class="footer-primary">
        <img src="../assets/logo1.png" alt="logo" width="50" height="50">
        <div class="footer-wrapper">
          <div class="wrapper-item">
            <h6>一木清单，让待办更简单</h6>
            <ul>
              <li class="normal">使用一木清单，高效记录一切，轻松规划生活~</li>
              <li class="normal">我们希望帮助你做到随想随记，事半功倍。</li>
            </ul>
          </div>
          <div class="wrapper-item">
            <h6>产品</h6>
            <ul>
              <li @click="footerClick('download')">下载应用</li>
              <li @click="footerClick('member')">高级会员</li>
            </ul>
          </div>
          <div class="wrapper-item">
            <h6>法律</h6>
            <ul>
              <li @click="footerClick('agreement')">用户协议</li>
              <li @click="footerClick('policy')">隐私政策</li>
            </ul>
          </div>
          <div class="wrapper-item">
            <h6>联系我们</h6>
            <ul>
              <li class="normal"><XIcon iconClass="QQ" />&nbsp;824186564（群）</li>
              <li class="normal"><XIcon iconClass="mail" />&nbsp;yimuyuzhou@163.com</li>
            </ul>
          </div>
        </div>
      </div>
      <Divider style="opacity: 0.08;" />
      <p style="color: #F0F0F0;">Copyright © 2022 杭州一木宇宙科技有限公司</p>
      <a href="https://beian.miit.gov.cn/">浙ICP备2022020351号-1</a>
      <div style="width:300px;margin:0 auto; padding:20px 0;">
        <a target="_blank" href=" " style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
          <img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">浙公网安备 33010902003411号</p ></a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      index: 0,
      screenWidth: 0,

      show: false
    }
  },
  
  mounted() {
    this.show = true

    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  },
  computed: {
    colCount() {
      if (this.screenWidth > 1024) {
        return 4;
      } else if (this.screenWidth > 560) {
        return 2;
      }
      return 1;
    }
  },
  methods: {
    downloadClick() {
      // window.open("https://pgyer.com/PqTe", '_blank');
      this.$store.state.showDownload = true
    },
    continueClick() {
      if (this.$store.state.user.userId) {
        this.$router.push('/manager/work');
      } else {
        this.$router.push({ name: 'login'});
      }
    },
    footerClick(key) {
      if (key == 'download') {
        this.$store.state.showDownload = true
      } else if (key == 'member') {
        this.$Message.info('努力开发中~');
      } else if (key == 'policy') {
        window.open('/privacyPolicy.html', '_blank');
      } else if (key == 'agreement') {
        window.open('/userAgreement.html', '_blank');
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .primary {
    display: flex;
    align-items: center;
    flex-direction: column;
    .intro {
      width: 100%;
      min-height: 680px;
      display: flex;
      // align-items: center;
      background-color: #fff;
      padding: 60px 8% 80px 8%;
      position: relative;
      .info {
        flex: 5;
        margin-right: 5px;
        margin-top: 12%;
        p.subtitle {
          margin: 32px 0 96px 0; 
        }
      }
      .intro-button {
        width: 180px;
        height: 54px;
        text-align: center;
        color: #fff;
        border-radius: 37px;
        font-size: 16px;
        background-color: #3D87DA;
        line-height: 54px;
        cursor: pointer;
        &.normal {
          background-color: #00BAAD;
        }
      }
      .image {
        margin: auto;
        flex: 5;
        min-width: 200px;
      }
    }
    .desc {
      background-color: #ECF1F7;
      padding: 100px 12%;
      text-align: center;
      .desc-subtitle {
        font-size: 17px;
        line-height: 36px;
        margin: 32px 0px 80px 0px;
      }
      .desc-card {
        text-align: center;
        background-color: #fff;
        border-radius: 16px;
        margin: 12px;
        padding: 0px 22px 22px 22px;
        img {
          width: 160px;
          height: 160px;
        }
      }
    }
    .slogan {
      width: 100%;
      text-align: center;
      padding: 80px 0;
      background-color: #fff;
      p {
        color: #FF8D1A; 
        font-size: 24px; 
        line-height: 40px;
      }
    }
    .primary-footer {
      background-color: #0B1024;
      width: 100%;
      padding: 40px 8%;
      color: #FEFEFE;
      .footer-primary {
        display: flex;
        .footer-wrapper {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .wrapper-item {
            margin-left: 10px;
            h6 {
              font-weight: 400;
              margin-bottom: 10px;
              line-height: 50px;
            }
            ul {
              flex: 0 0 40%;
              li {
                color: #E5E5E5;
                margin-bottom: 15px;
                cursor: pointer;
                &:hover {
                  text-decoration:underline;
                }
                &.normal {
                  cursor: auto;
                  &:hover {
                    text-decoration: none;
                  }
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 768px) {
      .intro {
        padding-top: 32px;
        text-align: center;
        display: block;
        min-height: auto;
        .info {
          width: 100%;
          p.subtitle {
            margin: 16px 0 28px 0; 
          }
        }
        .image {
          margin-top: 28px;
          min-width: 200px;
        }
      }
      .desc {
        padding: 36px 20px;
        .desc-subtitle {
          margin: 32px 0px 40px 0px;
        }
      }
    }
  }
</style>