<template>
  <div id="xmap">https://sortablejs.github.io/vue.draggable.next/#/nested-example</div>
</template>

<script>
export default {
  data() {
    return {
      longitude: 116.397128,
      latitude: 39.916527,

      map: null,
      marker: null,
    }
  },
  mounted() {
    this.getMyLocation();
  },
  methods: {
    getMyLocation() {
      var geolocation = new window.qq.maps.Geolocation("OX2BZ-LLMCJ-NABFD-DONPY-7QKIT-SJFB5", "yimutodo");
      geolocation.getLocation(this.showPosition, this.showErr);
    },
    showPosition(position) {
      console.log(position);
      this.latitude = position.lat;
      this.longitude = position.lng;
      this.setMap();
    },
    showErr() {
      console.log("定位失败");
    },
    setMap() {
      if (this.map) return
      var myLatlng = new window.TMap.LatLng(this.latitude, this.longitude)
      var myOptions = {
        zoom: 13, 
        center: myLatlng,
      }
      this.map = new window.TMap.Map(document.getElementById("xmap"), myOptions);
      this.map.on("panend", () => {
        var mapCenter = this.map.getCenter();
        console.log(mapCenter);
        console.log("当前地图中心为: " + mapCenter.getLat().toFixed(6) + "," + mapCenter.getLng().toFixed(6));
      })

      this.marker = new window.TMap.MultiMarker({
        map: this.map, //指定地图容器
        styles: {
          // 点标记样式
          marker1: new window.TMap.MarkerStyle({
            width: 20, // 样式宽
            height: 30, // 样式高
            anchor: { x: 10, y: 30 }, // 描点位置
          }),
        },
        // 点标记数据数组
        geometries: [
          {
            id: 'demo',
            styleId: 'marker1', // 和上面styles里面的marker1 向对应
            position: myLatlng, // 标点的位置
            properties: {
              title: 'marker',
            },
          },
        ],
      });
      var infoWindow = new window.TMap.InfoWindow({
        map: this.map,
        enableCustom: true,
        position: myLatlng,
        // offset: { y: -70, x: -5 },
        content: '<div class="info_card"><div class="title"><span class="title_name">腾讯北京总部大楼</span><div class="close_img" onclick="closeInfoWindow()"><span class="min top_img"></span><span class="min right_img"></span><span class="min bottom_img"></span><span class="min left_img"></span></div></div><div align="left" class="content">点击右键变更内容</div><span class="cancle bot"></span><span class="cancle top"></span></div>'
      });
      var openInfoWindow = () => infoWindow.open()
      // var closeInfoWindow = () => infoWindow.close()
      this.marker.on('click', openInfoWindow);
    }
  }
}
</script>