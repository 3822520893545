<template>
  <div>
    <CellGroup>
      <Cell
        v-for="(item, index) in dataList"
        :key="index"
        :title="showTitle(item)"
        :icon="item.icon"
        @click="onCellClick(item)"
        >
        <template #icon>
          <Icon 
            :type="cellCheck(item) ? 'ios-checkmark-circle' : 'md-radio-button-off'" 
            class="primary-color" 
            style="font-size: 16px;" 
          />
        </template>
        <template #extra>
          <Icon v-if="item.self" type="ios-close" style="font-size: 24px;" @click.stop="onCellClear(item)"/>
        </template>
      </Cell>
    </CellGroup>
    <Button type="text" @click="onCustomClick">
      <span class="primary-color" >+&nbsp;&nbsp;自定义提醒时间</span>
    </Button>
    <Modal :mask="false"
      :width="420"
      v-model="showModal"
      @on-ok="onModalOk">
      <p style="margin-bottom: 12px;">自定义时间</p>
      <div style="display: flex; align-items: center; margin-top: 25px; justify-content: space-between;">
        提前
        <InputNumber style="width: 50px;" :max="60" :min="0" v-model="customInfo.day" />
        天
        <InputNumber style="width: 50px;" :max="59" :min="0" v-model="customInfo.hour" />
        :
        <InputNumber style="width: 50px;" :max="59" :min="0" v-model="customInfo.minute" />

        <!-- <el-time-picker
          v-model="value1"
          :clearable="false"
          format="HH:mm"
          value-format="HH:mm"
          placeholder="选择时间">
        </el-time-picker> -->
      </div>
    </Modal>
  </div>
</template>

<script>

const noticeList1 = [{
  mode:0,
  msg:"无提醒",
  day:0,
  hour:0,
  minute:0,
  self:false
}, {
  mode:1,
  msg:"准时提醒",
  day:0,
  hour:0,
  minute:0,
  self:false,
}, {
  mode:1,
  msg:"提前5分钟",
  day:0,
  hour:0,
  minute:5,
  self:false,
}, {
  mode:1,
  msg:"提前10分钟",
  day:0,
  hour:0,
  minute:10,
  self:false,
}, {
  mode:1,
  msg:"提前30分钟",
  day:0,
  hour:0,
  minute:30,
  self:false,
}, {
  mode:1,
  msg:"提前1小时",
  day:0,
  hour:1,
  minute:0,
  self:false,
}, {
  mode:1,
  msg:"提前1天",
  day:1,
  hour:0,
  minute:0,
  self:false,
}];

const noticeList2 = [{
  mode:0,
  msg:"无提醒",
  day:0,
  hour:0,
  minute:0,
  self:false
}, {
  mode:2,
  msg:"当天9:00",
  day:0,
  hour:9,
  minute:0,
  self:false,
}, {
  mode:2,
  msg:"当天10:00",
  day:0,
  hour:10,
  minute:0,
  self:false,
}, {
  mode:2,
  msg:"提前1天20:00",
  day:1,
  hour:20,
  minute:0,
  self:false,
}, {
  mode:2,
  msg:"提前1周20:00",
  day:7,
  hour:20,
  minute:0,
  self:false,
}];

export default {
  props: {
    // 没有时间就0 有时间就1
    type: {
      type: Number,
      default: 0,
    },
    modelValue: {
      type: Array,
      default: () => [],
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      noticeList1,
      noticeList2,

      showModal: false,
      customList: [],
      dataList: [],
      customInfo: {
        day: 0,
        hour: 0,
        minute: 0,
      }
    };
  },
  mounted() {
    this.packageList();
  },
  methods: {
    packageList() {
      let baseList = this.type == 0 ? noticeList2 : noticeList1;
      let totalList = [...baseList, ...this.customList]
      let clist = this.modelValue.filter(item => {
        return !totalList.some(subItem => 
          subItem.day == item.day && 
            subItem.hour == item.hour && 
            subItem.minute == item.minute
        );
      });
      this.customList = this.customList.concat(clist);
      this.dataList = totalList.concat(clist);
    },
    showTitle(item) {
      if (item.mode == 0) {
        return '无提醒';
      } else if (item.mode == 2) {
        if (item.day == 0) {
          if (item.hour == 0 && item.minute == 0) {
            return '准时提醒';
          } else {
            return '当天' + `${item.hour}`.padStart(2, '0') + ':' + `${item.minute}`.padStart(2, '0');
          }
        } else {
          return '提前' + (item.day == 7 ? '一周' : `${item.day}天`) + `${item.hour}`.padStart(2, '0') + ':' + `${item.minute}`.padStart(2, '0');
        }
      } else if (item.mode == 1) {
        if (item.day == 0 && item.hour == 0 && item.minute == 0) {
          return '准时提醒';
        } else {
          let day = item.day == 0 ? '' : `${item.day}天`;
          let hour = item.hour == 0 ? '' : `${item.hour}小时`;
          let minute = item.minute == 0 ? '' : `${item.minute}分钟`;
          return '提前' + day + hour + minute;
        }
      }
    },
    cellCheck(item) {
      if (item.mode == 0 && this.modelValue.length == 0) {
        return true;
      }
      return this.modelValue.some(
        subItem => item.mode == subItem.mode &&
          item.day == subItem.day &&
          item.hour == subItem.hour &&
          item.minute == subItem.minute
      );
    },
    onCustomClick() {
      this.showModal = true;
    },
    onCellClick(item) {
      if (item.mode == 0) {
        if (this.modelValue.length > 0) {
          this.$emit('update:modelValue', []);
        }
      } else {
        // let index = this.modelValue.indexOf(item);
        let index = -1;
        for (let i = 0; i < this.modelValue.length; i++) {
          const subItem = this.modelValue[i];
          if (item.mode == subItem.mode &&
          item.day == subItem.day &&
          item.hour == subItem.hour &&
          item.minute == subItem.minute) {
            index = i;
            break;
          }
        }

        if (index == -1) {
          let list = [...this.modelValue];
          list.push(item);
          this.$emit('update:modelValue', list);
        } else {
          let list = [...this.modelValue];
          list.splice(index, 1)
          this.$emit('update:modelValue', list);
        }
      }
    },
    onCellClear(item) {
      let index = this.customList.indexOf(item);
      if (index > -1) {
        this.customList.splice(index, 1);
      }
      index = this.modelValue.indexOf(item);
      let list = [...this.modelValue];
      if (index > -1) {
        list.splice(index, 1)
      }
      this.$emit('update:modelValue', list);
    },
    onModalOk() {
      let index = this.modelValue.findIndex(item => this.itemEqual(item, this.customInfo));
      if (index > -1) {
        let list = [...this.modelValue];
        list.splice(index, 1);
        this.$emit('update:modelValue', list);
      } else {
        let baseList = this.type == 0 ? noticeList2 : noticeList1;
        let totalList = [baseList, ...this.customList];
        let sIndex = totalList.findIndex(item => this.itemEqual(item, this.customInfo));
        if (sIndex > -1) {
          let list = [...this.modelValue];
          list.push(totalList[sIndex]);
          this.$emit('update:modelValue', list);
        } else {
          let item = {
            mode: this.type == 0 ? 2 : 1,
            day: this.customInfo.day,
            hour: this.customInfo.hour,
            minute: this.customInfo.minute,
            self: true
          };
          item.msg = this.showTitle(item);
          this.customList.push(item);
          let list = [...this.modelValue];
          list.push(item);
          this.$emit('update:modelValue', list);
        }
      }

      // let clist = this.dataList.concat(this.modelValue);
      // let contain = clist.some(item => 
      //   this.customInfo.day == item.day && 
      //   this.customInfo.hour == item.hour && 
      //   this.customInfo.minute == item.minute
      // );
      // if (!contain) {
      //   let list = [...this.modelValue];
      //   let item = {
      //     mode: this.type == 0 ? 2 : 1,
      //     day: this.customInfo.day,
      //     hour: this.customInfo.hour,
      //     minute: this.customInfo.minute,
      //     self: true
      //   };
      //   let title = this.showTitle(item);
      //   item.msg = title;
      //   list.push(item);
      //   this.$emit('update:modelValue', list);
      // }
    },
    itemEqual(item, subItem) {
      return subItem.mode == item.mode && 
        subItem.day == item.day && 
        subItem.hour == item.hour && 
        subItem.minute == item.minute;
    }
  },
  watch: {
    modelValue() {
      this.packageList();
    },
    type() {
      this.packageList();
    }
  }
}
</script>