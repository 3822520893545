<template>
  <div>
    <CellGroup>
      <Cell
          v-for="(item, index) in dataList"
          :key="index"
          :title="item"
          @click="onCellClick(item)"
      >
        <template #icon>
          <Icon
              :type="modelValue == item ? 'ios-checkmark-circle' : 'md-radio-button-off'"
              class="primary-color"
              style="font-size: 16px;"
          />
        </template>
        <template #extra>
          <Icon v-if="!isOriginTag(item)" type="ios-close" style="font-size: 24px;" @click.stop="onCellClear(item)"/>
        </template>
      </Cell>
    </CellGroup>
    <Button type="text" @click="onCustomClick">
      <span class="primary-color" >+&nbsp;&nbsp;自定义单位</span>
    </Button>
    <Modal :mask="false"
           :width="420"
           v-model="showModal"
           :loading="modalLoading"
           @on-ok="onModalOk">
      <p style="margin-bottom: 12px;">自定义单位</p>
      <div style="display: flex; align-items: center; margin-top: 25px; flex-direction: column;">
        <Input v-model="customInfo" placeholder="请输入自定义单位" style="width: 100%" />
      </div>
    </Modal>
  </div>
</template>

<script>

import 'element-plus/es/components/date-picker/style/css';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'dayjs/locale/zh-cn';

const unitList = ["次","杯","个","米","页","毫升","分钟"];

export default {
  components: {
  },
  props: {
    modelValue: {
      type: String,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      locale: zhCn,

      showModal: false,
      modalLoading: false,
      dataList: [],
      customInfo: ''

    };
  },
  mounted() {
    this.packageList();
  },
  methods: {
    isOriginTag(item){
      return unitList.find(i=>i == item)
    },
    packageList() {
      this.dataList = [...unitList];
      if (!this.isOriginTag(this.modelValue)) {
        this.customInfo = this.modelValue;
        this.dataList = [...this.dataList, this.modelValue];
      }
    },
    onCustomClick() {
      this.showModal = true;
    },
    onCellClick(item) {
      this.$emit('update:modelValue', item);
    },
    onCellClear() {
      this.$emit('update:modelValue', unitList[0]);
    },
    onModalOk() {
      this.$emit('update:modelValue', this.customInfo);
    },
  },
  watch: {
    modelValue() {
      this.packageList();
    },
  }
}
</script>

<style lang="less" scoped>
</style>
