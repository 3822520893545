<template>
  <keep-alive>
    <router-view v-if="$route.meta.keepAlive"></router-view>
  </keep-alive>
  <router-view v-if="!$route.meta.keepAlive"></router-view>

  <Modal
    footerHide
    :width="420"
    v-model="showModal"
    :closable="false"
    :mask-closable="false"
  >
    <XClockInDialog />
  </Modal>
</template>

<script>
import { h } from "vue";
import { Modal } from "view-ui-plus";
import "element-plus/es/components/notification/style/css";
import { ElNotification } from "element-plus";
import XClockInDialog from "@/components/XClockInDialog";
import { channelEvent, getPCClientVersion } from "@/common/api";
export default {
  name: "App",
  components: { XClockInDialog, Modal },
  data() {
    return {
      incrementDate: {},
    };
  },
  computed: {
    showModal() {
      return this.$store.state.showClockDialog;
    },
  },
  mounted() {
    if (process?.env?.IS_ELECTRON) {
      setTimeout(() => {
        this.getRemoteVersion();
      }, 5000);
      this.ipcRendererInit();
    }
  },
  methods: {
    ipcRendererInit() {
      const { ipcRenderer } = require("electron");
      ipcRenderer.on("notice", (event, data) => {
        this.incrementDate = data;
        this.notice();
      });
      ipcRenderer.on("updateNotice", () => {
        this.getRemoteVersion();
      });
    },
    handleLinkClick(event) {
      event.preventDefault();
      channelEvent("increment", JSON.parse(JSON.stringify(this.incrementDate)));
    },
    notice() {
      ElNotification({
        type: "success",
        title: `发现新版本${this.incrementDate.version}~`,
        message: h("div", [
          h("p", `${this.incrementDate.message}`),
          h(
            "a",
            {
              href: "#",
              style: {
                color: "#409EFF",
                display: "inline-block",
                float: "right",
                fontSize: "12px",
              },
              onClick: this.handleLinkClick,
            },
            "立即升级"
          ),
        ]),
        duration: 0,
        customClass: "custom-notification",
        dangerouslyUseHTMLString: false,
      });
    },
    onUpdateOk() {
      channelEvent("increment", JSON.parse(JSON.stringify(this.incrementDate)));
    },
    getRemoteVersion() {
      getPCClientVersion("PC_CLIENT_VERSION").then((res) => {
        const data = JSON.parse(res[0].value) || {};
        channelEvent("remoteVersion", data);
      });
    },
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  height: 100%;
}
</style>
