import { DomEditor } from '@wangeditor/editor'
import { Boot } from '@wangeditor/editor'
import { h } from 'snabbdom'

// const resume = {                    // JS 语法
//   type: 'xdivider',
//   color: '#000',
//   type: 'solid',
//   children: [{ text: '' }] 
// }

// function withAttachment(editor) {
//   const { isInline, isVoid } = editor
//   const newEditor = editor

//   newEditor.isInline = elem => {
//     const type = DomEditor.getNodeType(elem)
//     if (type === 'xdivider') return true
//     return isInline(elem)
//   }

//   newEditor.isVoid = elem => {
//     const type = DomEditor.getNodeType(elem)
//     if (type === 'xdivider') return true
//     return isVoid(elem)
//   }

//   return newEditor
// }

function renderDivider(elemNode, children, editor) {
  const { line = 'solid', color = '#000' } = elemNode
  const selected = DomEditor.isNodeSelected(editor, elemNode)

  const hrnode = h(
    'hr',
    {
      style: { 
        border: 'none', 
        borderTop: `1px ${line} ${color}`, 
        backgroundColor: 'transparent'
      }
    }
  )

  const vnode = h(
    'div',
    {
      props: {
        contentEditable: false,
        className: 'w-e-textarea-divider',
      },
      dataset: {
        selected: selected ? 'true' : '',
      },
      style: {},
      on: {
        mousedown: event => event.preventDefault(),
      },
    },
    [hrnode]
  )

  return vnode
}

const renderElemConf = {
  type: 'divider',
  renderElem: renderDivider,
}

Boot.registerRenderElem(renderElemConf)