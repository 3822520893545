<template>
  <div class="account">
    <Form label-position="top">
      <p style="margin-bottom: 14px;">账号与安全</p>
      <FormItem label="">
        <Space>
          <Avatar :size="60" :src="require('../assets/header_avatar.png')" icon="ios-person" />
          <div style="line-height: 14px;">
            <span>快乐的小猪&nbsp;&nbsp;</span>
            <XTextButton color="orange" @click="deleteAccount">修改手机号</XTextButton>
            <div style="height: 6px;"></div>
            <span style="color: gray; font-size: 13px;">您正在使用一木清单免费版&nbsp;&nbsp;</span>
            <Button type="warning" size="small">立即升级</Button>
          </div>
        </Space>
      </FormItem>
      <p style="margin-bottom: 14px;">账号信息</p>
      <FormItem label="密码">
        <Space>
          {{ "已设置" }}
          <XTextButton @click="deleteAccount">修改密码</XTextButton>
        </Space>
        <Login v-show="editType==''" ref="passwordForm" @on-submit="passwordSubmit" style="width: 340px;">
          <Space direction="vertical" :size="24">
            <Password name="password" placeholder="当前密码" />
            <Password name="passwordNew" placeholder="新密码" />
            <Space>
              <Button style="width: 165px;" size="large">取消</Button>
              <Button @click="passwordClick" style="width: 165px;" size="large" type="primary">确定</Button>
            </Space>
          </Space>
        </Login>
      </FormItem>
      <FormItem label="手机号">
        <Space>
          {{ "156****1796" }}
          <XTextButton @click="deleteAccount">修改手机号</XTextButton>
          <XTextButton @click="deleteAccount">解除绑定</XTextButton>
        </Space>
      </FormItem>
      <FormItem label="邮箱">
        <Space>
          <XTextButton @click="deleteAccount">设置邮箱</XTextButton>
        </Space>
      </FormItem>
      <FormItem label="第三方账号绑定">
        <Space>
          <XIcon iconClass="wechat"></XIcon>
          <span>微信</span>
          <XTextButton @click="bindingWechat">去绑定</XTextButton>
        </Space>
        <br>
        <Space>
          <XIcon iconClass="qqb"></XIcon>
          <span>QQ</span>
          <XTextButton @click="bindingQQ">去绑定</XTextButton>
        </Space>
      </FormItem>      
      <FormItem label="账号管理">
        <XTextButton @click="deleteAccount">删除账号</XTextButton>
      </FormItem>
    </Form>
  </div>
</template>

<script>

// import { modifyPassword } from '@/common/api';
import XTextButton from '@/components/XTextButton'

export default {
  components: {
    XTextButton
  },
  data() {
    return {
      editType: '',
    }
  },
  methods: {
    bindingQQ() {
      
    },
    bindingWechat() {

    },
    deleteAccount() {
      console.log('asdasdasdad');
    },
    passwordCancel() {

    },
    passwordClick() {
      this.$refs.passwordForm.handleSubmit();
    },
    passwordSubmit(valid, { password, passwordNew }) {
      if (valid) {
        // login(username, password).then(res => {
        //   if (res) {
        //     this.$store.commit('updateInfo', res);
        //     this.$router.push('/manager/work');
        //   }
        // });
        console.log(password);
        console.log(passwordNew);
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .account {
    padding: 20px;
  }
</style>