<template>
  <a href="javascript:void(0)" :style="{color: color, fontSize: size + 'px'}">
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: null
    },
    size: {
      type: Number,
      default: 12
    }
  }
}
</script>