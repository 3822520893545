<template>
  <span class="icon-button">
    <XIcon :iconClass="name" :size="iconSize" :style="`color: ${iconColor}`" />
    <Text 
      :style="`font-size: ${titleSize}px; margin-left: 4px; color: ${titleColor};`" 
      ellipsis 
      :ellipsis-config="{ tooltip: true }">
      {{ title }}
    </Text>
  </span>
</template>

<script>

  export default {
    props: {
      title: {
        type: String,
        default: ''
      }, 
      name: {
        type: String,
        default: ''
      },
      titleSize: {
        type: String,
        default: '14'
      },
      titleColor: {
        type: String,
        default: '#999'
      },
      iconSize: {
        type: String,
        default: '16'
      },
      iconColor: {
        type: String,
        default: ''
      },
    },
  }
</script>

<style lang="less" scoped>
  .icon-button {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    align-items: center;
    cursor: pointer;
  }
</style>