class BackgroundColorMenu {
  constructor() {
      this.title = '背景色'
      this.iconSvg = '<svg viewBox="0 0 1024 1024"><path d="M510.030769 315.076923l84.676923 196.923077h-177.230769l76.8-196.923077h15.753846zM945.230769 157.538462v708.923076c0 43.323077-35.446154 78.769231-78.769231 78.769231H157.538462c-43.323077 0-78.769231-35.446154-78.769231-78.769231V157.538462c0-43.323077 35.446154-78.769231 78.769231-78.769231h708.923076c43.323077 0 78.769231 35.446154 78.769231 78.769231z m-108.307692 643.938461L600.615385 216.615385c-5.907692-11.815385-15.753846-19.692308-29.538462-19.692308h-139.815385c-11.815385 0-23.630769 7.876923-27.56923 19.692308l-216.615385 584.861538c-3.938462 11.815385 3.938462 25.6 17.723077 25.6h80.738462c11.815385 0 23.630769-9.846154 27.56923-21.661538l63.015385-175.261539h263.876923l68.923077 175.261539c3.938462 11.815385 15.753846 21.661538 27.569231 21.661538h80.738461c13.784615 0 23.630769-13.784615 19.692308-25.6z"></path></svg>' // 可选
      this.tag = 'button'
  }

  getValue(editor) {
    console.log(editor);
    return ' hello '
  }

  isActive(editor) {
    console.log(editor);
    return false
  }

  isDisabled(editor) {
    console.log(editor);
    return false
  }

  // 点击菜单时触发的函数
  exec(editor, value) {
    if (this.isDisabled(editor)) return
    editor.insertText(value)
  }
}

const menuConf = {
  key: 'backgroundColor',
  factory() {
    return new BackgroundColorMenu()
  },
}

import { Boot } from '@wangeditor/editor'

Boot.registerMenu(menuConf)